import CompanyApi from '../../services/company-api.service'
import Logger from '@/plugins/logger'

export default {
  createImportRequest: async ({ state }, { file }) => {
    try {
      if (!file) {
        throw new Error('File is required')
      }

      const extension = file?.key?.split('.').pop()
      const payload = {
        file: {
          key: file.key,
          type: extension.toUpperCase(),
        },
      }

      return CompanyApi.createImportRequest(payload)
    } catch (error) {
      Logger.error(error?.message || 'Error on creating import-request', {
        tags: [['action', 'import-requests/create-import-request']],
        company: state.company,
      })

      throw error
    }
  },

  getImportRequests: async ({ dispatch, state }, { page = 1 }) => {
    try {
      const results = await CompanyApi.listImportRequests({ page })

      if (!results?.items?.length) {
        return { ...results, items: [] }
      }

      const promises = results.items.map(async (item) => {
        item.employee = await dispatch('getEmployee', item.adminId)
        return item
      })

      const items = await Promise.all(promises)

      return { ...results, items }
    } catch (error) {
      Logger.error(error?.message || 'Error on loading import-requests', {
        tags: [['action', 'import-requests/get-import-requests']],
        company: state.company,
      })

      throw error
    }
  },
}
