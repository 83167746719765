import { Auth } from '@/plugins/firebase'
import Logger from '@/plugins/logger'

import UploaderService from '../../services/uploader.service'

const actions = {
  /**
   * Get signed url for upload
   */
  getSignedUploadUrl: async ({ state }, { bucket, params }) => {
    params.companyId = state.companyId

    const response = await UploaderService.getSignedURL(bucket, params).catch(
      (error) => {
        Logger.error(error?.message, {
          tags: [['action', 'uploader/get-s3-file-url']],
          companyId: state?.companyId,
          userUID: Auth.currentUser.uid,
          params,
          response: error?.response,
        })

        return {
          error: {
            message: error?.message,
            code: error?.code,
            response: error?.response,
          },
        }
      },
    )

    return response
  },

  /**
   * Retrieves the file url
   */
  getS3FileUrl: async ({ state }, { bucket, params }) => {
    if (!params) {
      return
    }

    params.companyId = state?.companyId

    const response = await UploaderService.getFileURL(bucket, params).catch(
      (error) => {
        Logger.error(error?.message, {
          tags: [['action', 'uploader/get-s3-file-url']],
          companyId: state?.companyId,
          userUID: Auth.currentUser.uid,
          params,
        })

        return {
          error: {
            message: error?.message,
            code: error?.code,
            response: error?.response,
          },
        }
      },
    )

    return response
  },

  destroyS3File: async ({ state }, fullKey) => {
    const params = {
      companyId: state?.companyId,
      fullKey,
    }

    const response = await UploaderService.destroyFile(params).catch(
      (error) => {
        Logger.error(error?.message, {
          tags: [['action', 'uploader/destroy-s3-file']],
          companyId: state?.companyId,
          userUID: Auth.currentUser.uid,
          params,
        })

        return {
          error: {
            message: error?.message,
            code: error?.code,
            response: error?.response,
          },
        }
      },
    )

    return response
  },
}

export default actions
