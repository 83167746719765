import Vue from 'vue'
import VueI18n from 'vue-i18n'

const localeFiles = {
  en: require('../locales/en.json'),
  es: require('../locales/es.json'),
  'pt-BR': require('../locales/pt-BR.json'),
}

Vue.use(VueI18n)

export const loadLocaleMessages = () => {
  return Object.keys(localeFiles).reduce((locales, lang) => {
    locales[lang] = localeFiles[lang]
    return locales
  }, {})
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'pt-BR',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt-BR',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
})
