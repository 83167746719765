const ENV = process.env.VUE_APP_ENV
const APP_URL = process.env.VUE_APP_STAGING_URL
const CLIENT_KEY = process.env.VUE_APP_COMPANY_ID_KEY
const COMPANY_ID = localStorage.getItem(CLIENT_KEY)

const isProduction = ENV === 'production'
const isStaging = ENV === 'staging'

const STATUSES = ['NEW', 'OPENED', 'UNDER_REVIEW', 'SOLVED']

const AREAS = [
  'product',
  'sales',
  'marketing',
  'hr',
  'admin',
  'financial',
  'atm',
  'compliance',
  'c-level',
  'technology',
]

const FREQUENCY_ITEMS = [
  {
    id: 'once',
    localeKey: 'EMBED_REPORT.FREQUENCY_ANSWER_FIRST_TIME',
    value: false,
  },
  {
    id: 'more-than-once',
    localeKey: 'EMBED_REPORT.FREQUENCY_ANSWER_HAPPENED_BEFORE',
    value: true,
  },
]

const PER_PAGE_BOARD = 3

export {
  ENV,
  APP_URL,
  CLIENT_KEY,
  COMPANY_ID,
  STATUSES,
  AREAS,
  FREQUENCY_ITEMS,
  PER_PAGE_BOARD,
  isProduction,
  isStaging,
}
