const defaultCounters = {
  badges: {
    issues: 0,
    reports: 0,
  },
  issues: {
    new: 0,
    all: 0,
    solved: 0,
    pages: 0,
  },
  reports: {
    all: 0,
    new: 0,
    pages: 0,
  },
  disclosures: {
    all: 0,
    new: 0,
    pages: 0,
  },
  users: {
    all: 0,
    active: 0,
  },
  /**
   * @deprecated Will be deleted in version 0.8. Use reports and users object instead.
   */
  totalUserPages: 0,
}

const defaultState = {
  account: {},
  activities: [],
  adminId: null,
  areas: [],
  code: null,
  company: {},
  companyId: null,
  config: {},
  contents: [],
  conversation: [],
  counters: {},
  currentUser: null,
  defaultCounters,
  employees: [],
  error: null,
  files: [],
  issue: {},
  issues: [],
  disclosure: {},
  disclosures: [],
  issuesUnread: 0,
  loading: false,
  locale: 'pt',
  notes: [],
  notice: null,
  permissions: [],
  relatedReports: [],
  replies: [],
  report: {},
  reportee: null,
  reportId: null,
  reports: [],
  resolutionNote: null,
  success: null,
  tenant: null,
  token: null,
  assignMisconducts: [],
  misconducts: [],
  branches: [],
  reportAssignees: [],
  documents: [],
  trainings: [],

  /**
   * @deprecated Will be deleted pretty soon.
   */
  pagesReports: 0,
  totalReports: 0,
}

export default defaultState
