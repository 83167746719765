<template>
  <section class="no-analytics-section">
    <div class="modal">
      <p class="modal__title">
        <slot name="title" />
      </p>

      <p class="modal__description">
        <slot name="description" />
      </p>

      <div class="modal__action">
        <slot name="action" />
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style scoped>
.no-analytics-section {
  width: 100%;
  height: 100%;
  min-height: 75vh;
  background: url('@/assets/images/admin-dash-blur.png') no-repeat left center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  align-items: center;
}

.no-analytics-section .modal {
  background-color: var(--color-white);
  box-shadow: var(--shadow-default);
  border-radius: var(--border-radius-default);
  width: 360px;
  max-width: 100%;
  margin: auto;
  padding: 40px;
  text-align: center;
}

.no-analytics-section .modal .modal__title {
  font-weight: bold;
  font-size: var(--heading-1);
  color: var(--color-pink-default);
  margin-top: 0;
}

.no-analytics-section .modal .modal__description {
  margin: 2rem auto;
}

.no-analytics-section .modal .modal__action {
  color: var(--color-pink-default);
}

.no-analytics-section .modal .modal__action a {
  font-weight: bold;
  text-decoration: underline;
}

.no-analytics-section .modal .modal__action a.btn {
  widows: fit-content;
  text-decoration: none;
  margin: 25px auto;
  font-size: 12px;
}
</style>
