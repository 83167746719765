import axios from 'axios'
import { TOKEN } from '../globals'
import { Auth } from './firebase'
import Logger from './logger'
import { GenericError } from './errors'
import { getDDSessionId } from '@/services/session.service'

const getUserToken = async () => {
  if (!Auth.currentUser) {
    return
  }

  try {
    const { token } = await Auth.currentUser.getIdTokenResult(true)

    localStorage.setItem(TOKEN, token)

    return token
  } catch (err) {
    Logger.error(err.message)
  }
}

const headers = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
}

const authInterceptor = async (config) => {
  const token = (await getUserToken()) || localStorage.getItem(TOKEN)

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  const sessionId = getDDSessionId()
  config.headers['x-dd-session-id'] = sessionId

  return config
}

const authErrorHandler = (error) => {
  const context = {
    message: error.message,
    status: error.status || error?.response?.statusCode || 500,
    ...error?.response?.data,
    code: error?.code || 'auth-api-error',
  }

  if (context.status >= 500) {
    Logger.error(error.message, { context })
  }

  return Promise.reject(error)
}

const errorHandler = (error) => {
  const context = error?.response?.data
  const message = context?.message || error?.message || 'Firebase Service Error'
  Logger.error(message, { context, error })

  return Promise.reject(new GenericError(message))
}

const authAPI = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API,
  headers,
  withCredentials: true,
})

const firebaseServiceAPI = axios.create({
  baseURL: process.env.VUE_APP_SERVELESS_API,
  headers,
  withCredentials: true,
})

const companyApi = axios.create({
  baseURL: process.env.VUE_APP_COMPANY_API,
  headers,
  withCredentials: true,
})

firebaseServiceAPI.interceptors.request.use(authInterceptor, errorHandler)
authAPI.interceptors.request.use(authInterceptor, authErrorHandler)
authAPI.interceptors.response.use(null, authErrorHandler)
companyApi.interceptors.request.use(authInterceptor, null)
companyApi.interceptors.response.use(authInterceptor, null)

export { authAPI, firebaseServiceAPI, companyApi }
