import arrowLeftIcon from '../../../assets/images/icon_arrow_left.svg'
import arrowRightIcon from '../../../assets/images/icon_arrow_right.svg'

export default {
  name: 's-paginate',
  props: {
    pages: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      currentPage: 1,
      arrowLeftIcon,
      arrowRightIcon,
    }
  },

  methods: {
    nextPage() {
      this.currentPage += 1
      this.$emit('next', this.currentPage)
    },

    previousPage() {
      this.currentPage -= 1
      this.$emit('previous', this.currentPage)
    },
  },

  watch: {
    currentPage() {
      this.$emit('paginate', this.currentPage)
    },
  },
}
