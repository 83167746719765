<template>
  <div class="dashboard">
    <section v-if="isAnalyticsEnabled" class="charts">
      <ChartsContainer />
    </section>
    <EmptyDash v-if="!isAnalyticsEnabled">
      <template #title>{{ $t('RESTRICTED_ANALYTICS.TITLE') }}</template>
      <template #description>
        {{ $t('RESTRICTED_ANALYTICS.DESCRIPTION') }}
      </template>
      <template #action>
        <a href="mailto:suporte@safe.space" target="_blank" class="btn">
          {{ $t('RESTRICTED_ANALYTICS.CTA') }}
        </a>
      </template>
    </EmptyDash>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChartsContainer from '@/components/Dashboard/ChartsContainer'
import EmptyDash from '@/components/Dashboard/EmptyDash'

export default {
  name: 'Dashboard',

  components: {
    ChartsContainer,
    EmptyDash,
  },

  beforeRouteEnter(__, _from, next) {
    next((vm) => {
      const isFeatureEnabled = vm.$store.getters.isReportingChannelEnabled
      if (!isFeatureEnabled) {
        next('/settings')
      }
    })
  },

  computed: mapGetters(['isAnalyticsEnabled']),
}
</script>

<style scoped>
.dashboard {
  height: 100%;
}
.charts {
  width: 100%;
}
</style>
