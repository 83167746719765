<template>
  <form data-v-step="2">
    <div>
      <input
        id="table"
        v-model="display"
        type="radio"
        name="choice"
        value="table"
        :checked="display === 'table'"
      />
      <label for="table" class="table">
        <slot name="table" />
      </label>
    </div>

    <div>
      <input
        id="board"
        v-model="display"
        type="radio"
        name="choice"
        value="board"
        :checked="display === 'board'"
      />
      <label for="board" class="board">
        <slot name="board" />
      </label>
    </div>
  </form>
</template>

<script>
import Metrics from '@/plugins/metrics'

export default {
  name: 'Toggle',

  data() {
    return {
      display: localStorage.getItem('_rd') || 'board',
    }
  },

  watch: {
    display() {
      localStorage.setItem('_rd', this.display) // _rd: report display
      Metrics.track('Reports View Changed', { currentPreference: this.display })
      this.$emit('toggleView', this.display)
    },
  },
}
</script>

<style scoped>
form {
  display: flex;
  margin: auto 11px;
}

input[type='radio'] {
  display: none;
}

label {
  display: inline-block;
  text-align: center;
  color: var(--color-pink-default);
  cursor: pointer;
  padding: 5px 10px;
  background-color: var(--color-white);
  border: 1px solid var(--color-pink-default);
}

.table {
  border-radius: 20px 0 0 20px;
  padding-right: 20px;
}

.board {
  border-radius: 20px;
  margin-left: -15px;
}

input:checked ~ .table,
input:checked ~ .board {
  background-color: var(--color-pink-default);
  color: var(--color-white);
}
</style>
