import { companyApi, firebaseServiceAPI as legacyAPI } from '../plugins/axios'
import Logger from '../plugins/logger'

const UploaderService = {
  getSignedURL: async (bucket, params) => {
    Logger.info('[Uploader] Get signed URL', params)
    if (bucket === 'company-api') {
      return CompanyAPIUploadService.getSignedURL(params)
    }

    return LegacyFirebaseUploadService.getSignedURL(params)
  },

  getFileURL: async (bucket, params) => {
    Logger.info('[Uploader] Get file', params)
    if (bucket === 'company-api') {
      return CompanyAPIUploadService.getFileURL(params)
    }

    return LegacyFirebaseUploadService.getFileURL(params)
  },

  destroyFile: async (fullKey) => {
    Logger.info('[Uploader] Destroy file', { fullKey })
    return LegacyFirebaseUploadService.destroyS3File(fullKey)
  },
}

const CompanyAPIUploadService = {
  getSignedURL: async (params) => {
    const { key, prefix, companyId } = params

    const response = await companyApi
      .post(`/companies/${companyId}/upload`, { key, prefix })
      .catch((error) => {
        Logger.error(error.message)
      })

    return response?.data?.data
  },

  getFileURL: async (params) => {
    const { companyId, key, prefix } = params

    const response = await companyApi
      .get(`/companies/${companyId}/upload?key=${key}&prefix=${prefix}`)
      .catch((error) => {
        Logger.error(error.message)
      })

    return response.data
  },
}

/**
 * TODO: prepare report-api to handle legacy uploads
 */
const LegacyFirebaseUploadService = {
  getSignedURL: async (params) => {
    const response = await legacyAPI.post(
      '/file/upload/signed',
      {
        key: params.fullKey,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      },
    )

    return response?.data
  },

  getFileURL: async (params) => {
    const file = params?.fullKey || params?.key
    if (!file) {
      return null
    }

    return legacyAPI
      .get('/file/', {
        params: { file },
        withCredentials: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => response.data?.url)
  },

  destroyS3File: async (key) => {
    return legacyAPI.delete('/file', { params: { file: key } })
  },
}

export default UploaderService
