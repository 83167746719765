import { pick } from 'lodash'
import { companyApi as api } from '../../plugins/axios'
import { Auth, DB, docIdField } from '@/plugins/firebase'
import Logger from '@/plugins/logger'
import Metrics from '@/plugins/metrics'
import { FEATURES } from '@/globals'
import { CAN_UPDATE_COMPANY } from '../permissions'

const actions = {
  async getCompanyById({ commit }, claims) {
    commit('setLoading', true)
    const { schemaName, tenant } = claims

    if (!Auth.currentUser) {
      await Auth.signInAnonymously()
    }

    const companySchemaName = tenant || schemaName

    const { empty, docs } = await DB.collection('companies')
      .where('schemaName', '==', companySchemaName)
      .limit(1)
      .get()
      .catch((err) => commit('setError', err.code))

    if (empty) {
      commit('setError', 'UNAVAILABLE')
      return null
    }

    const company = docs[0]

    commit('setLoading', false)

    if (company) {
      const { config, name, defaultLanguage = 'pt' } = company.data()
      const { features = {}, sso = [] } = config

      commit('mutate', {
        property: 'company',
        value: { name, tenant: companySchemaName },
      })
      commit('mutate', { property: 'tenant', value: companySchemaName })
      commit('mutate', { property: 'companyId', value: company.id })
      commit('setLocale', defaultLanguage)

      const isSsoEnabled = features?.singleSignOn
      const [provider] = sso?.providers || []

      return {
        name,
        defaultLanguage,
        isSsoEnabled,
        provider,
      }
    }
  },

  async getCompanyByTenant({ commit, dispatch, state }) {
    if (!state.tenant) {
      commit('setError', 'UNAVAILABLE')
      Logger.debug('Can`t find company by tenant', {
        tags: [['action', 'companies/get-company-by-tenant']],
        company: state.company,
        user: state.account,
      })

      return false
    }

    const response = await DB.collection('companies')
      .where('tenant', '==', state.tenant)
      .limit(1)
      .get()
      .catch((err) => {
        commit('setError', err.code)
        Logger.error('Error on retrieving company', {
          tags: [['action', 'companies/get-company-by-tenant']],
          tenant: state.tenant,
          company: state.company,
          user: state.account,
        })
      })

    const company = response?.docs && response.docs[0]

    if (!company?.id) {
      await dispatch('signOut')
      commit('setError', 'UNAVAILABLE')
      Logger.error('Error on retrieving company', {
        tags: [['action', 'companies/get-company-by-tenant']],
        tenant: state.tenant,
        company: state.company,
        user: state.account,
      })
      throw new Error('UNAVAILABLE')
    }

    const {
      config,
      name,
      slug,
      relations = {},
      defaultLanguage = 'pt-BR',
    } = company.data()

    commit('setCompany', { id: company.id, name, relations, slug })

    Metrics.people.set('Company ID', company.id)
    Logger.setUserProperty('companyId', company.id)
    Logger.setUserProperty('companyIName', company.name)

    commit('mutate', { property: 'companyId', value: company.id })
    commit('mutate', { property: 'config', value: config })
    commit('setLocale', defaultLanguage)
    commit('setError', null)
    localStorage.setItem(FEATURES, JSON.stringify(config.features))
  },

  getCounters({ state, commit, dispatch }) {
    const allCounters = {
      ...state.defaultCounters,
      ...state.counters,
    }

    dispatch('getIssuesCounter').then(({ all, newers, pages }) => {
      allCounters.issues.all = all
      allCounters.issues.new = newers
      allCounters.issues.pages = pages
      allCounters.badges.issues = newers
    })
    dispatch('getDisclosuresCounter').then(({ all, newers, pages }) => {
      allCounters.disclosures.all = all
      allCounters.disclosures.new = newers
      allCounters.disclosures.pages = pages
      allCounters.badges.disclosures = newers
    })

    allCounters.badges = {
      disclosures: allCounters.disclosures.new,
      issues: allCounters.issues.new,
      reports: allCounters.reports.new,
    }

    commit('mutate', { property: 'counters', value: allCounters })
  },

  updateCounters({ commit, state }, payload) {
    const params = pick(payload, ['counters.users', 'counters.activeUsers'])
    DB.collection('companies')
      .doc(state.companyId)
      .update(params)
      .catch((err) => commit('setError', err.code))
  },

  // Company Contents

  async createCompanyContent({ commit, dispatch, state }, payload) {
    if (!state.companyId) {
      await dispatch('getCompanyByTenant')
    }

    await dispatch('checkPermission', [CAN_UPDATE_COMPANY])

    const companyRef = DB.collection('companies').doc(state.companyId)

    await DB.collection('company_contents')
      .add({ ...payload, companyRef })
      .then(() => dispatch('getCompanyContents'))
      .catch((err) => commit('setError', err))
  },

  async getCompanyContents({ commit, dispatch, state }) {
    if (!state.companyId) {
      await dispatch('getCompanyByTenant')
    }

    const companyRef = DB.collection('companies').doc(state.companyId)

    await DB.collection('company_contents')
      .where('companyRef', '==', companyRef)
      .orderBy('createdAt', 'asc')
      .get()
      .then(({ docs }) => {
        commit('mutate', {
          property: 'contents',
          value: docs.map((doc) => {
            const { readers = [], ...rest } = doc.data()
            const payload = { countReader: readers.length, ...rest, id: doc.id }

            return payload
          }),
        })
      })
      .catch((err) => commit('setError', err))
  },

  async getCompanyContentsByEmployeeId({ state }, employeeId) {
    const companyRef = DB.collection('companies').doc(state.companyId)

    const { docs } = await DB.collection('company_contents')
      .where('companyRef', '==', companyRef)
      .where('readers', 'array-contains', employeeId)
      .get()

    const contents = docs.map((doc) => {
      const content = doc.data()

      return content.title
    })

    return contents
  },

  async destroyCompanyContent({ commit, dispatch, state }, contentId) {
    const companyRef = DB.collection('companies').doc(state.companyId)

    await dispatch('checkPermission', [CAN_UPDATE_COMPANY])

    const recordExists = await DB.collection('company_contents')
      .where('companyRef', '==', companyRef)
      .where(docIdField, 'in', [contentId])
      .get()
      .then((snap) => !snap.empty)
      .catch((err) => commit('setError', err))

    if (recordExists) {
      DB.collection('company_contents')
        .doc(contentId)
        .delete()
        .then(() => dispatch('getCompanyContents'))
        .catch((err) => commit('setError', err))
    }
  },

  async getCompanyBranches({ commit, state }) {
    const { data } = await api
      .get(`/companies/${state.companyId}/branches?active=true`)
      .catch(({ message }) => {
        throw new Error(message)
      })

    const { data: result = [] } = data

    commit('setBranches', result)

    return result
  },

  async getCompanyBranchByKey({ state, commit }, companyBranchKey) {
    try {
      const { data } = await api.get(
        `/companies/${state.companyId}/branches?key=${companyBranchKey}`,
      )

      return data.data[0]
    } catch (err) {
      commit('setError', err)
      Logger.error('Error on retrieving branch by key', {
        tags: [['action', 'companies/getCompanyBranchByKey']],
        branch: companyBranchKey,
        company: state.company,
        user: state.account,
      })
    }
  },
}

export default actions
