import { apolloClient as graphql } from '@/apolloProvider'
import { GET_AREAS } from '@/graphql/queries'
import { CREATE_AREA, UPDATE_AREA } from '@/graphql/mutations'

export default {
  getCompanyAreas: async ({ commit }, enabledOnly) => {
    const {
      data: { areas },
    } = await graphql.query({
      query: GET_AREAS,
      variables: { enabled: enabledOnly },
      fetchPolicy: 'no-cache',
    })

    commit('mutate', { property: 'areas', value: areas })

    return areas
  },

  createCompanyArea: async ({ commit }, params) => {
    commit('setLoading', true)
    commit('setGraphQLError', [])

    const { pt, en, es } = params

    await graphql
      .mutate({
        mutation: CREATE_AREA,
        variables: { pt, en, es },
      })
      .catch((err) => commit('setGraphQLError', err))
      .finally(commit('setLoading', false))
  },

  updateCompanyArea: (_, payload) => {
    const { id, enabled, pt, en, es } = payload
    graphql.mutate({
      mutation: UPDATE_AREA,
      variables: { id, enabled, pt, en, es },
    })
  },
}
