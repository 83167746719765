import Vue from 'vue'
import VueRouter from 'vue-router'
import Firebase from '@/plugins/firebase'
import { groupPermissions, checkPermission } from '@/store/permissions'
import { features, checkFeaturesEnabled } from '@/store/features'

const Login = () => import(/* webpackChunkName: "Login" */ '@/views/Auth/Login')
const PasswordRecovery = () =>
  import(/* webpackChunkName: "Setup" */ '@/views/Auth/PasswordRecovery')
const Register = () =>
  import(/* webpackChunkName: "Register" */ '@/views/Auth/Register')

import Reports from '@/views/Reports'

// Analytics
import Dashboard from '@/views/Dashboard'

const Layout = () => import(/* webpackChunkName: "Layout" */ '@/views/Layouts')

const Terms = () => import(/* webpackChunkName: "Terms" */ '@/views/Terms')
const Privacy = () =>
  import(/* webpackChunkName: "Privacy" */ '@/views/Privacy')

const Users = () => import(/* webpackChunkName: "Users" */ '@/views/Employees')
const Report = () => import(/* webpackChunkName: "Report" */ '@/views/Report')
const FollowUp = () =>
  import(/* webpackChunkName: "FollowUp" */ '@/views/FollowUp')
const Faq = () => import(/* webpackChunkName: "Faq" */ '@/views/Faq')
const Settings = () =>
  import(/* webpackChunkName: "Settings" */ '@/views/Settings')
const Support = () =>
  import(/* webpackChunkName: "Support" */ '@/views/Support')
const EmbedReport = () =>
  import(/* webpackChunkName: "EmbedReport" */ '@/views/EmbedReport')
const Issues = () => import(/* webpackChunkName: "Issues" */ '@/views/Issues')
const IssuesConversation = () =>
  import(
    /* webpackChunkName: "IssuesConversation" */ '@/views/Issues/Conversation'
  )
const Policies = () =>
  import(/* webpackChunkName: "Policies" */ '@/views/Policies')

const Trainings = () =>
  import(/* webpackChunkName: "Trainings" */ '@/views/Trainings')

const Policy = () => import(/* webpackChunkName: "Policy" */ '@/views/Policy')

const Training = () =>
  import(/* webpackChunkName: "Training" */ '@/views/Training')

const Disclosures = () =>
  import(/* webpackChunkName: "Disclosures" */ '@/views/Disclosures')
const Disclosure = () =>
  import(/* webpackChunkName: "Disclosures" */ '@/views/Disclosure')

const NotFound = () =>
  import(/* webpackChunkName: "Layout" */ '@/views/Layouts/404')
import Maintenance from '@/components/Maintenance.vue'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'index-pt-BR',
    component: Login,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/setup',
    name: 'setup-pt-BR',
    component: PasswordRecovery,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
  },
  {
    path: '/dashboard',
    component: Layout,
    meta: { authenticated: true },
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: Reports,
        meta: { reuse: false },
      },
      {
        path: '/group/:groupId',
        name: 'reports-groups',
        component: Reports,
        props: ({ query, params }) => ({
          ...params,
          ...query,
        }),

        meta: { reuse: false },
      },
      {
        path: '/report/:reportId',
        name: 'report-pt-BR',
        component: Report,
        meta: {
          requiredPermissions: groupPermissions.REPORT,
        },
      },
      {
        path: '/report/reply/:reportId',
        name: 'report-reply-pt-BR',
        component: FollowUp,
        meta: {
          requiredPermissions: groupPermissions.REPORT,
        },
      },
      {
        path: '/users',
        name: 'user-ids-pt-BR',
        component: Users,
        meta: {
          requiredPermissions: groupPermissions.USERS,
        },
      },
      { path: '/faq', name: 'faq-pt-BR', component: Faq },
      {
        path: '/analytics',
        name: 'analytics',
        component: Dashboard,
        meta: {
          requiredPermissions: groupPermissions.ANALYTICS,
        },
      },
      { path: '/settings', name: 'settings', component: Settings },
      {
        path: '/support',
        name: 'support-pt-BR',
        component: Support,
        meta: {
          requiredPermissions: groupPermissions.SUPPORT,
        },
      },
      {
        path: '/new-report',
        name: 'new-report-pt-BR',
        component: EmbedReport,
        meta: {
          requiredPermissions: groupPermissions.CREATE_REPORT,
        },
      },
      {
        path: '/issues',
        name: 'issues',
        component: Issues,
        meta: {
          requiredPermissions: groupPermissions.ISSUES,
        },
      },
      {
        path: '/issues/:issueId',
        name: 'issue-conversation',
        component: IssuesConversation,
        meta: {
          requiredPermissions: groupPermissions.ISSUES,
        },
      },
      {
        path: '/disclosures',
        name: 'disclosures',
        component: Disclosures,
        meta: {
          requiredPermissions: groupPermissions.DISCLOSURES,
          requiredFeatures: [features.DISCLOSURES],
        },
      },
      {
        path: '/disclosure/:disclosureId',
        name: 'disclosure',
        component: Disclosure,
        meta: {
          requiredPermissions: groupPermissions.DISCLOSURES,
          requiredFeatures: [features.DISCLOSURES],
        },
      },
      {
        path: '/policies',
        name: 'policies',
        component: Policies,
        meta: {
          requiredPermissions: groupPermissions.DOCUMENTS,
        },
      },
      {
        path: '/policy/:documentId',
        name: 'policy',
        component: Policy,
        meta: {
          requiredPermissions: groupPermissions.DOCUMENTS,
        },
        props: ({ query, params }) => ({
          ...params,
          ...query,
        }),
      },
      {
        path: '/trainings',
        name: 'trainings',
        component: Trainings,
        meta: {
          requiredPermissions: groupPermissions.TRAININGS,
        },
      },
      {
        path: '/training/:id',
        name: 'training',
        component: Training,
        meta: {
          requiredPermissions: groupPermissions.TRAININGS,
        },
        props: ({ query, params }) => {
          return {
            ...params,
            ...query,
            trainingId: params.id,
          }
        },
      },
    ],
  },
  { path: '/maintenance', component: Maintenance },
  { path: '*', component: NotFound },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, _from, next) => {
  const mustBeAuthenticated = to.matched.some(
    (record) => record.meta.authenticated,
  )

  const { requiredPermissions = [], requiredFeatures = [] } = to.meta

  if (requiredPermissions.length) {
    if (!checkPermission(requiredPermissions)) {
      next({ name: 'settings', params: { error: 'access-denied' } })
    }
  }

  if (requiredFeatures.length) {
    if (!checkFeaturesEnabled(requiredFeatures)) {
      next({ name: 'settings', params: { error: 'access-denied' } })
    }
  }

  const currentUser = await Firebase.getCurrentUser()

  to.params['currentUserId'] = currentUser?.uid

  if (mustBeAuthenticated && !currentUser) {
    next('/login')
  } else next()
})

export default router
