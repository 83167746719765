import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import fetch from 'unfetch'

import { TOKEN } from './globals'
import { isProduction, isStaging } from './utils/constants'
import { getDDSessionId } from './services/session.service'

const cache = new InMemoryCache()

const authLink = setContext((_, { headers, ...context }) => {
  const accessToken = localStorage.getItem(TOKEN)
  const sessionId = getDDSessionId()

  return {
    headers: {
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
      'x-dd-session-id': sessionId,
    },
    ...context,
  }
})

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_REPORT_API,
  fetch,
})

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache,
  connectToDevTools: !isProduction && !isStaging,
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

Vue.use(VueApollo)

export default apolloProvider
