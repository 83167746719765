import { Auth, DB, Timestamp } from '../../plugins/firebase'
import Logger from '../../plugins/logger'
import { groupPermissions } from '../permissions'

const getNotes = async ({ commit, state, dispatch }) => {
  try {
    const { docs } = await DB.collection('notes')
      .where('reportId', '==', state.report.id)
      .orderBy('createdAt', 'asc')
      .get()

    const notesPromise = docs.map(async (doc) => {
      const { id } = doc
      const {
        message,
        createdAt,
        reportId,
        employeeName = 'ADMIN',
        type,
        deleted,
      } = doc.data()

      const payload = {
        id,
        message,
        createdAt,
        reportId,
        identity: employeeName,
        type,
        source: 'ADMIN',
        userUID: Auth.currentUser.uid,
        typeMessage: 'notes',
        deleted,
      }

      if (type === 'FILE') {
        const fileUrl = await dispatch('getS3FileUrl', {
          params: { key: message },
        })

        return {
          src: fileUrl,
          thumb: fileUrl,
          ...payload,
        }
      }

      return payload
    })

    const notes = await Promise.all(notesPromise)

    commit('setNotes', notes)
    commit('setError', null)
  } catch (err) {
    Logger.error(err.message)
    commit('setError', err.code)
  }
}

const createNote = async ({ state, commit, dispatch }, payload) => {
  await dispatch('checkPermission', groupPermissions.REPORT)
  commit('setError', null)

  const { message, type } = payload
  const timestamp = Timestamp.now()
  const createdAt = timestamp

  try {
    const { id } = await DB.collection('notes').add({
      type,
      reportId: state.report.id,
      employeeId: state.adminId,
      employeeName: state.employeeName,
      message,
      createdAt,
      userUID: Auth.currentUser.uid,
    })

    commit('updateNoteByMessageAndTimestamp', {
      message,
      id,
      createdAt: timestamp,
    })

    if (type === 'FILE') {
      const fileUrl = await dispatch('getS3FileUrl', {
        params: { key: message },
      })
      return { src: fileUrl, ...payload, createdAt, id }
    }

    if (id) {
      dispatch('activityLog', {
        reportId: state.report.id,
        message: 'ACTIVITY_REPORT_ADD_NOTE',
        visible: false,
      })
    }

    return {
      ...payload,
      createdAt,
      id,
      reportId: state.report.id,
      identity: state.employeeName,
      source: 'ADMIN',
      userUID: Auth.currentUser.uid,
      typeMessage: 'notes',
    }
  } catch (err) {
    const previousNotes = [...state.notes]
    Logger.error(err?.message || err?.code, {
      tags: [['action', 'notes/createNote']],
      companyId: state?.companyId,
      userUID: state?.adminId,
      response: err,
    })
    commit('setNotes', previousNotes)
  }
}

const deleteNotes = async ({ state, commit, dispatch }, notes_id) => {
  await dispatch('checkPermission', groupPermissions.REPORT)
  commit('setLoading', true)

  if (!state.companyId) {
    await dispatch('getCompanyByTenant')
  }

  try {
    const payload = {
      deleted: true,
    }

    await DB.collection('notes').doc(notes_id).update(payload)
  } catch (error) {
    Logger.error(error.message, {
      tags: [['action', 'notes/delete-notes']],
      companyId: state.companyId,
      notesId: notes_id,
      userUID: Auth.currentUser.uid,
    })
  }
}

export default {
  createNote,
  getNotes,
  deleteNotes,
}
