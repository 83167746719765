import { apolloClient as graphql } from '@/apolloProvider'

import { GET_MISCONDUCTS } from '@/graphql/queries'

export default {
  getMisconducts: async ({ commit }) => {
    const {
      data: { misconducts },
    } = await graphql.query({ query: GET_MISCONDUCTS })

    const filterMisconducts = misconducts.filter(
      ({ localeKey }) => localeKey !== 'OTHER',
    )

    commit('setMisconducts', filterMisconducts)
  },
  getMisconductsWithOther: async ({ commit }) => {
    const {
      data: { misconducts },
    } = await graphql.query({ query: GET_MISCONDUCTS })
    commit('setMisconducts', misconducts)
  },
}
