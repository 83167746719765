import { LOCAL_STORAGE_KEYS } from '@/globals'

const CAN_UPDATE_REPORT = 'CAN_UPDATE_REPORT'
const CAN_CREATE_REPORT = 'CAN_EMBED_REPORT'
const CAN_ACCESS_ASSIGNED_REPORTS = 'CAN_ACCESS_ASSIGNED_REPORTS'
const CAN_READ_ISSUES = 'CAN_READ_ISSUES'
const CAN_UPDATE_ISSUES = 'CAN_UPDATE_ISSUES'
const CAN_READ_DISCLOSURES = 'CAN_READ_DISCLOSURES'
const CAN_UPDATE_DISCLOSURES = 'CAN_UPDATE_DISCLOSURES'
const CAN_READ_USERS = 'CAN_READ_USERS'
const CAN_UPDATE_USERS = 'CAN_UPDATE_USERS'
const CAN_IMPORT_USERS = 'CAN_IMPORT_USERS'
const CAN_ACCESS_ANALYTICS = 'CAN_ACCESS_ANALYTICS'
const CAN_ACCESS_SUPPORT = 'CAN_ACCESS_SUPPORT'
const CAN_UPDATE_COMPANY = 'CAN_UPDATE_COMPANY'
const CAN_MODERATE_ADMINS = 'CAN_MODERATE_ADMINS'
const CAN_CREATE_DOCUMENT = 'CAN_CREATE_DOCUMENT'
const CAN_UPDATE_DOCUMENT = 'CAN_UPDATE_DOCUMENT'
const CAN_CREATE_TRAININGS = 'CAN_CREATE_TRAININGS'
const CAN_UPDATE_TRAININGS = 'CAN_UPDATE_TRAININGS'

const REPORT_GROUP_PERMISSIONS = [
  CAN_UPDATE_REPORT,
  CAN_ACCESS_ASSIGNED_REPORTS,
]

const CREATE_REPORT_GROUP_PERMISSIONS = [CAN_CREATE_REPORT]

const ISSUES_GROUP_PERMISSIONS = [CAN_READ_ISSUES, CAN_UPDATE_ISSUES]

const DISCLOSURES_GROUP_PERMISSIONS = [
  CAN_READ_DISCLOSURES,
  CAN_UPDATE_DISCLOSURES,
]

const USERS_GROUP_PERMISSIONS = [
  CAN_READ_USERS,
  CAN_IMPORT_USERS,
  CAN_UPDATE_USERS,
]

const ANALYTICS_GROUP_PERMISSIONS = [CAN_ACCESS_ANALYTICS]

const SUPPORT_GROUP_PERMISSIONS = [CAN_ACCESS_SUPPORT]

const COMPANY_GROUP_PERMISSIONS = [CAN_UPDATE_COMPANY]

const MODERATE_ADMINS_GROUP_PERMISSIONS = [CAN_MODERATE_ADMINS]

const DOCUMENTS_GROUP_PERMISSIONS = [CAN_CREATE_DOCUMENT, CAN_UPDATE_DOCUMENT]

const TRAININGS_GROUP_PERMISSIONS = [CAN_CREATE_TRAININGS, CAN_UPDATE_TRAININGS]

const groupPermissions = {
  REPORT: REPORT_GROUP_PERMISSIONS,
  ISSUES: ISSUES_GROUP_PERMISSIONS,
  DISCLOSURES: DISCLOSURES_GROUP_PERMISSIONS,
  USERS: USERS_GROUP_PERMISSIONS,
  ANALYTICS: ANALYTICS_GROUP_PERMISSIONS,
  SUPPORT: SUPPORT_GROUP_PERMISSIONS,
  COMPANY: COMPANY_GROUP_PERMISSIONS,
  CREATE_REPORT: CREATE_REPORT_GROUP_PERMISSIONS,
  MODERATE: MODERATE_ADMINS_GROUP_PERMISSIONS,
  DOCUMENTS: DOCUMENTS_GROUP_PERMISSIONS,
  TRAININGS: TRAININGS_GROUP_PERMISSIONS,
}

export const checkPermission = (permissions) => {
  const userPermissions =
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PERMISSIONS)) || []
  return userPermissions.some((capability) => permissions.includes(capability))
}

export {
  CAN_UPDATE_REPORT,
  CAN_UPDATE_ISSUES,
  CAN_UPDATE_COMPANY,
  CAN_UPDATE_USERS,
  CAN_UPDATE_DISCLOSURES,
  CAN_MODERATE_ADMINS,
  groupPermissions,
}
