import { apolloClient as graphql } from '@/apolloProvider'

import { GET_ANALYTICS_REPORTS } from '@/graphql/queries'

export default {
  getAnalyticsReports: async (_, lang = 'pt') => {
    const {
      data: { analytics: result },
    } = await graphql.query({
      query: GET_ANALYTICS_REPORTS,
      variables: {
        lang,
      },
    })

    return result
  },
}
