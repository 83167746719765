import { DB } from '@/plugins/firebase'
import Logger from '@/plugins/logger'

import { map, last } from 'lodash'

import { groupPermissions } from '../permissions'

const PER_PAGE = 10

const actions = {
  async getDisclosure({ commit, dispatch }, disclosureId) {
    await dispatch('checkPermission', groupPermissions.DISCLOSURES)
    commit('mutate', { property: 'loading', value: true })

    const snap = await DB.collection('disclosures')
      .doc(disclosureId)
      .get()
      .catch((err) => commit('setError', err.message))
      .finally(() => commit('mutate', { property: 'loading', value: false }))

    if (!snap.exists) {
      return {}
    }

    const { status } = snap.data()

    const disclosure = {
      id: snap.id,
      ...snap.data(),
      status: status === 'NEW' ? 'OPEN' : status,
    }

    commit('mutate', { property: 'disclosure', value: disclosure })

    if (status === 'NEW') {
      dispatch('updateDisclosure', {
        id: disclosureId,
        payload: { status: 'OPEN' },
      })
    }

    return disclosure
  },

  async updateDisclosure({ commit, dispatch }, { id, payload }) {
    await dispatch('checkPermission', groupPermissions.DISCLOSURES)

    return DB.collection('disclosures')
      .doc(id)
      .update(payload)
      .catch((err) => commit('setError', err.code))
      .finally(() => commit('mutate', { property: 'loading', value: false }))
  },

  preFetchDisclosures({ state }, sortBy) {
    const companyRef = DB.collection('companies').doc(state.companyId)
    return DB.collection('disclosures')
      .where('companyRef', '==', companyRef)
      .orderBy('createdAt', sortBy)
  },

  async getCompanyDisclosures({ commit, dispatch }, sortBy) {
    await dispatch('checkPermission', groupPermissions.DISCLOSURES)

    commit('mutate', { property: 'loading', value: true })

    await dispatch('getCompanyByTenant')

    const query = await dispatch('preFetchDisclosures', sortBy)

    query
      .limit(PER_PAGE)
      .get()
      .then((snap) => dispatch('loadDisclosures', snap))
      .catch((err) => commit('setError', err.code))
      .finally(() => commit('mutate', { property: 'loading', value: false }))
  },

  async getCompanyDisclosuresByStatus(
    { commit, dispatch },
    statusList,
    orderBy,
  ) {
    commit('mutate', { property: 'loading', value: true })
    const query = await dispatch('preFetchDisclosures', orderBy)
    query
      .limit(PER_PAGE)
      .where('status', 'in', statusList)
      .get()
      .then((snap) => dispatch('loadDisclosures', snap))
      .catch((err) => commit('setError', err.code))
      .finally(() => commit('mutate', { property: 'loading', value: false }))
  },

  loadDisclosures({ commit, dispatch }, snap) {
    commit('mutate', { property: 'firstDisclosure', value: snap.docs[0] })
    commit('mutate', { property: 'lastDisclosure', value: last(snap.docs) })
    if (!snap.empty) {
      const disclosures = map(snap.docs, (doc) => {
        return { ...doc.data(), id: doc.id }
      })
      commit('mutate', { property: 'disclosures', value: disclosures })
      dispatch('getCounters')
    } else {
      commit('mutate', { property: 'disclosures', value: [] })
    }
    commit('mutate', { property: 'loading', value: false })
  },

  async paginateDisclosures(
    { commit, dispatch, state },
    { direction, orderBy },
  ) {
    commit('mutate', { property: 'loading', value: true })
    let query = await dispatch('preFetchDisclosures', orderBy)

    if (direction == 'forward') {
      query = query.startAfter(state.lastDisclosure).limit(PER_PAGE)
    } else {
      query = query.endBefore(state.firstDisclosure).limitToLast(PER_PAGE)
    }

    query
      .get()
      .then((snap) => dispatch('loadDisclosures', snap))
      .catch((err) => commit('setError', err.message))
  },

  async getDisclosuresCounter({ state: { companyId } }) {
    try {
      const companyRef = DB.collection('companies').doc(companyId)
      const query = DB.collection('disclosures').where(
        'companyRef',
        '==',
        companyRef,
      )

      const { size: all } = await query.get()

      const { size: newers } = await query.where('status', '==', 'NEW').get()
      const pages = Math.ceil(all / PER_PAGE)

      return { all, newers, pages }
    } catch (error) {
      Logger.debug(error.message, {
        tags: [['action', 'disclosures/get-disclosures-counter']],
        companyId,
      })
      throw error
    }
  },
}

export default actions
