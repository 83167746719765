import { DB, Timestamp } from '@/plugins/firebase'
import Logger from '@/plugins/logger'

import { pick, map, last, omit } from 'lodash'

import { groupPermissions, CAN_UPDATE_ISSUES } from '../permissions'

const PER_PAGE = 15

const actions = {
  async getIssue({ state, commit, dispatch }, issueId) {
    try {
      await dispatch('checkPermission', groupPermissions.ISSUES)

      commit('mutate', { property: 'loading', value: true })

      const snap = await DB.collection('issues').doc(issueId).get()

      if (snap.exists) {
        const { status, protocol } = snap.data()
        commit('mutate', { property: 'issueId', value: snap.id })
        commit('mutate', { property: 'protocol', value: protocol })
        commit('mutate', {
          property: 'issue',
          value: {
            ...omit(snap.data(), 'conversation'),
            id: snap.id,
            status: status === 'NEW' ? 'OPEN' : status,
          },
        })

        if (status === 'NEW') {
          dispatch('updateIssue', { status: 'OPEN' })
        }
      }
    } catch (err) {
      commit('setError', err?.code || err?.message || 'UNAVAILABLE')
      Logger.debug(err?.code || err?.message || 'Error on loading issue', {
        tags: [['action', 'issues/get-issue']],
        issueId,
        user: state.account,
        company: state.company,
        tenant: state.tenant,
      })
    } finally {
      commit('mutate', { property: 'loading', value: false })
    }
  },

  async updateIssue({ commit, state, dispatch }, payload) {
    await dispatch('checkPermission', groupPermissions.ISSUES)

    const params = pick(payload, ['status', 'unread', 'replied'])

    return DB.collection('issues')
      .doc(state.issueId)
      .update(params)
      .catch((err) => {
        commit('setError', err.code)
        Logger.debug(err?.code || err?.message || 'Error on loading issue', {
          tags: [['action', 'issues/update-issue']],
          user: state.account,
          company: state.company,
          tenant: state.tenant,
        })
      })
      .finally(() => commit('mutate', { property: 'loading', value: false }))
  },

  async sendIssueFollowUpMessage({ state, commit, dispatch }, data) {
    await dispatch('checkPermission', CAN_UPDATE_ISSUES)

    const payload = {
      ...data,
      issueId: state.issueId,
      source: 'ADMIN',
      sentAt: Timestamp.fromDate(new Date()),
    }

    await DB.collection('issues_follow_ups')
      .add(payload)
      .catch((err) => commit('setError', err.code))

    return {
      ...payload,
      sentAt: new Date(payload.sentAt.seconds * 1000),
    }
  },

  async getIssueFollowUpMessages({ state, commit, dispatch }, issueId) {
    await dispatch('checkPermission', groupPermissions.ISSUES)

    commit('mutate', { property: 'loading', value: true })
    commit('mutate', { property: 'conversation', value: [] })

    const documents = await DB.collection('issues_follow_ups')
      .where('issueId', '==', issueId)
      .orderBy('sentAt', 'asc')
      .get()

    commit('mutate', { property: 'loading', value: false })

    if (state.issue.unread > 0) {
      dispatch('updateIssue', { unread: 0 })
    }

    return documents.docs.map((doc) => {
      const data = doc.data()
      return {
        id: doc.id,
        ...data,
        sentAt: new Date(data.sentAt.seconds * 1000),
      }
    })
  },

  preFetchIssues({ state }, orderBy) {
    const companyRef = DB.collection('companies').doc(state.companyId)
    return DB.collection('issues')
      .where('companyRef', '==', companyRef)
      .orderBy('sentAt', orderBy)
  },

  async getCompanyIssues({ state, commit, dispatch }, orderBy) {
    await dispatch('checkPermission', groupPermissions.ISSUES)

    commit('mutate', { property: 'loading', value: true })

    await dispatch('getCompanyByTenant')

    const query = await dispatch('preFetchIssues', orderBy)

    const { size: all } = await query.get()
    dispatch('setTotalCount', all)

    query
      .limit(PER_PAGE)
      .get()
      .then((snap) => dispatch('loadIssues', snap.docs))
      .catch((err) => {
        commit('setError', err.code)
        Logger.debug(err?.code || err?.message || 'Error on loading issue', {
          tags: [['action', 'issues/get-company-issues']],
          user: state.account,
          company: state.company,
          tenant: state.tenant,
        })
      })
      .finally(() => commit('mutate', { property: 'loading', value: false }))
  },

  async getCompanyIssuesByFilters({ dispatch }, payload) {
    const { type, status } = payload.params
    if (type.length && status.length) {
      dispatch('getCompanyMultipleFilters', payload)
    } else {
      dispatch('getCompanyIssueUniqueFilter', payload)
    }
  },

  async getCompanyMultipleFilters({ state, commit, dispatch }, payload) {
    commit('mutate', { property: 'loading', value: true })
    if (payload.direction === 'backward') {
      state.currentPage = state.currentPage - 1
    } else if (payload.direction === 'forward') {
      state.currentPage = state.currentPage + 1
    } else {
      commit('mutate', { property: 'currentPage', value: 0 })
    }

    let query = await dispatch('preFetchIssues', payload.orderBy)

    const { type, status } = payload.params

    let query1 = query.where('type', 'in', type)
    let query2 = query.where('status', 'in', status)

    Promise.all([query1.get(), query2.get()])
      .then(([snap1, snap2]) => {
        const issues = snap1.docs.reduce((acc, obj) => {
          if (snap2.docs.some((obj2) => obj.id === obj2.id)) {
            acc.push(obj)
          }
          return acc
        }, [])
        dispatch('setTotalCount', issues.length)

        const issuesChunks = Array.from(
          { length: Math.ceil(issues.length / PER_PAGE) },
          (_, index) => issues.slice(index * PER_PAGE, (index + 1) * PER_PAGE),
        )

        const snap = issuesChunks[state.currentPage]
          ? issuesChunks[state.currentPage]
          : []
        dispatch('loadIssues', snap)
      })
      .catch((err) => {
        commit('setError', err.code)
        Logger.debug(err?.code || err?.message || 'Error on loading issue', {
          tags: [['action', 'issues/get-company-issues-by-status']],
          user: state.account,
          company: state.company,
          tenant: state.tenant,
        })
      })
      .finally(() => commit('mutate', { property: 'loading', value: false }))
  },

  async getCompanyIssueUniqueFilter({ state, commit, dispatch }, payload) {
    commit('mutate', { property: 'loading', value: true })

    let query = await dispatch('preFetchIssues', payload.orderBy)

    const { type, status } = payload.params
    if (type.length) query = query.where('type', 'in', type)

    if (status.length) query = query.where('status', 'in', status)
    const { size: all } = await query.get()

    dispatch('setTotalCount', all)

    query = await dispatch('paginateIssues', { query, payload })

    query
      .get()
      .then((snap) => dispatch('loadIssues', snap.docs))
      .catch((err) => {
        commit('setError', err.code)
        Logger.debug(err?.code || err?.message || 'Error on loading issue', {
          tags: [['action', 'issues/get-company-issues-by-status']],
          user: state.account,
          company: state.company,
          tenant: state.tenant,
        })
      })
      .finally(() => commit('mutate', { property: 'loading', value: false }))
  },

  loadIssues({ commit, dispatch }, snap) {
    commit('mutate', { property: 'firstIssue', value: snap[0] })
    commit('mutate', { property: 'lastIssue', value: last(snap) })

    if (!snap.empty) {
      const issues = map(snap, (doc) => {
        return { ...doc.data(), id: doc.id }
      })
      commit('mutate', { property: 'issues', value: issues })
      dispatch('getCounters')
    }
    commit('mutate', { property: 'loading', value: false })
  },

  async getIssuesCounter({ state }) {
    const { companyId, issuesTotal } = state
    try {
      const companyRef = DB.collection('companies').doc(companyId)
      const query = DB.collection('issues').where(
        'companyRef',
        '==',
        companyRef,
      )
      const { size: all } = await query.get()
      const { size: newers } = await query.where('status', '==', 'NEW').get()
      const pages = Math.ceil(issuesTotal / PER_PAGE)

      return { all, newers, pages }
    } catch (error) {
      Logger.debug(error.message, {
        tags: [['action', 'follow-ups/get-unread-messages']],
        companyId,
      })
      throw error
    }
  },

  async setTotalCount({ commit }, value) {
    try {
      commit('mutate', { property: 'issuesTotal', value: value })
    } catch (error) {
      Logger.debug('Error fetching and setting total count:', error)
      throw error
    }
  },

  paginateIssues({ state }, params) {
    const { query, payload } = params
    if (payload.direction === 'forward') {
      return query.startAfter(state.lastIssue).limit(PER_PAGE)
    } else if (payload.direction === 'backward') {
      return query.endBefore(state.firstIssue).limitToLast(PER_PAGE)
    } else {
      return query.limit(PER_PAGE)
    }
  },
}

export default actions
