/**
 * Anúncios do TopBanner
 */
import { DB } from '@/plugins/firebase'
/**
 * banner_notice document structure = {
 *  pt, en, es, variant, published, createdAt
 * }
 */

export default {
  async getLatestNotice() {
    const snap = await DB.collection('banner_notices')
      .where('published', '==', true)
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get()
      .catch(() => null)

    if (snap.empty) {
      return null
    }

    const [notice] = snap.docs

    return { id: notice.id, ...notice.data() }
  },
}
