const ROUTER_TITLES = {
  login: 'Entrar',
  'setup-pt-BR': 'Redefinir Senha Segura',
  register: 'Redefinir Senha Segura',
  'dashboard-pt-BR': 'DASHBOARD_HEADER_PANEL',
  'report-pt-BR': 'REPORT',
  'report-reply-pt-BR': 'REPORT',
  'user-ids-pt-BR': 'DASHBOARD_HEADER_USERS',
  'faq-pt-BR': 'DASHBOARD_HEADER_FAQ',
  settings: 'DASHBOARD_HEADER_SETTINGS',
  'support-pt-BR': 'DASHBOARD_HEADER_SUPPORT',
  issues: 'ISSUES_PANEL',
  'issue-conversation': 'ISSUE',
  analytics: 'DASHBOARD_HEADER_ANALYTICS',
  imports: 'IMPORT_REQUESTS.TITLE',
  policies: 'DASHBOARD_HEADER_DOCUMENTS',
  trainings: 'DASHBOARD_HEADER_TRAININGS',
}

const getReportsTableColumnHeaders = (
  isCriticalLevelEnabled,
  isCompanyBranchEnabled,
  isReportGroupsEnabled,
) => {
  const criticalLevelColumns = isCriticalLevelEnabled
    ? [
        {
          title: 'REPORTS_TABLE_COLUMN_RISK',
          shouldOrder: false,
        },
      ]
    : []

  const unitsTableColumnHeaders = isCompanyBranchEnabled
    ? [
        {
          title: 'REPORTS_TABLE_COLUMN_UNITS',
          shouldOrder: false,
        },
      ]
    : []

  const optionsTableColumnHeaders = isReportGroupsEnabled
    ? [
        {
          title: 'REPORTS_TABLE_COLUMN_OPTIONS',
          shouldOrder: false,
        },
      ]
    : []

  return [
    {
      title: ' ',
      shouldOrder: false,
    },
    {
      title: 'REPORTS_TABLE_COLUMN_STATUS',
      shouldOrder: false,
    },
    {
      title: 'REPORTS_TABLE_COLUMN_CODE',
      shouldOrder: false,
    },
    ...criticalLevelColumns,

    {
      title: '',
      shouldOrder: false,
    },
    {
      title: 'REPORTS_TABLE_COLUMN_CLASSIFICATION',
      shouldOrder: false,
    },

    ...unitsTableColumnHeaders,

    {
      title: 'REPORTS_TABLE_COLUMN_AREA',
      shouldOrder: false,
    },
    {
      title: '',
      shouldOrder: false,
    },
    {
      title: 'REPORTS_TABLE_COLUMN_PROFILE',
      shouldOrder: false,
    },
    {
      title: 'REPORTS_TABLE_COLUMN_DATE',
      shouldOrder: true,
      ordered: 'desc',
      column: 'createdAt',
    },
    ...optionsTableColumnHeaders,
  ]
}

const FIELDS = {
  misconductLocaleKeys: 'MISCONDUCTS',
  companyBranches: 'COMPANY_BRANCHES',
  criticalLevels: 'CRITICAL_LEVELS',
}

const getFilterEventTitle = (key) => {
  const field = FIELDS[key] || key.toUpperCase()
  return 'FILTERED_BY_' + field
}

export { ROUTER_TITLES, getReportsTableColumnHeaders, getFilterEventTitle }
