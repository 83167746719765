<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
    <PortalTarget name="root" />
  </div>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    isOffline: false,
  }),

  watch: {
    isOffline() {
      if (this.isOffline) {
        this.$toasted.show(this.$t('YOU_ARE_OFFLINE'), {
          type: 'error',
          position: 'top-center',
          duration: 10000,
        })
      }
    },
  },

  mounted() {
    window.addEventListener('offline', () => (this.isOffline = true))
    window.addEventListener('online', () => (this.isOffline = false))

    this.$i18n.locale = localStorage.locale || 'pt-BR'
  },
}
</script>

<style lang="css">
@import 'assets/styles/override.css';
@import 'assets/styles/app.css';
</style>
