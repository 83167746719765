const { VUE_APP_SUPPORT_WEBHOOK: SUPPORT_WEBHOOK } = process.env

export const sendMessageToSlack = (payload) => {
  if (!SUPPORT_WEBHOOK) {
    return
  }

  const { companyName, subject, username, message } = payload

  const options = {
    method: 'POST',
    body: JSON.stringify({
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Chegou uma mensagem da *${companyName}* :mailbox_with_mail:`,
          },
        },
        {
          type: 'section',
          fields: [
            {
              type: 'mrkdwn',
              text: `*Assunto*\n${subject || 'Não informado'}`,
            },
            {
              type: 'mrkdwn',
              text: `*Usuario*\n${username}`,
            },
            {
              type: 'mrkdwn',
              text: `*Mensagem*\n"${message}"`,
            },
          ],
        },
      ],
    }),
  }

  fetch(SUPPORT_WEBHOOK, options).then((response) => response.json())
}
