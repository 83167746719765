<template>
  <header class="header">
    <div class="header__top">
      <div class="container">
        <div class="report__total">
          <h1>
            {{ $t('REPORTS_TABLE_HEADER_TITLE') }}
          </h1>
          <span v-if="total >= 0" class="total">
            {{ pagination?.total || total }}
          </span>
          <span v-else>
            <div class="loader" />
          </span>
        </div>

        <div class="header__search">
          <input
            v-model="term"
            name="term"
            type="text"
            :placeholder="$t('SEARCH_INVOLVED')"
            @keyup.enter="filterReports"
          />
          <button @click="filterReports">
            <img :src="iconSend" :alt="$t('SEARCH')" />
          </button>
        </div>

        <div class="header__filters">
          <button
            class="toggle"
            data-v-step="0"
            @click.stop="showFilters = !showFilters"
          >
            <span>
              {{ $t('FILTERS') }}
            </span>
            <img :src="showFilters ? iconArrowUp : iconArrowDown" alt="" />
          </button>
          <div :class="['content', { enabled: showFilters }]">
            <menu>
              <li
                v-if="showStatusFilter"
                :class="{ selected: selectedFilter === 'status' }"
              >
                <button @click="selectedFilter = 'status'">
                  {{ $t('STATUS') }}
                  <span>({{ statusFilter.length }})</span>
                </button>
              </li>
              <li :class="{ selected: selectedFilter === 'misconducts' }">
                <button @click="selectedFilter = 'misconducts'">
                  {{ $t('MISCONDUCT') }}
                  <span>({{ selectedMisconducts.length }})</span>
                </button>
              </li>
              <li :class="{ selected: selectedFilter === 'areas' }">
                <button @click="selectedFilter = 'areas'">
                  {{ $t('AREA') }}
                  <span>({{ selectedAreas.length }})</span>
                </button>
              </li>
              <li
                v-if="isCompanyBranchEnabled"
                :class="{ selected: selectedFilter === 'branches' }"
              >
                <button @click="selectedFilter = 'branches'">
                  {{ $t('BRANCH') }}
                  <span>({{ selectedBranches.length }})</span>
                </button>
              </li>
              <li :class="{ selected: selectedFilter === 'source' }">
                <button @click="selectedFilter = 'source'">
                  {{ $t('SOURCE') }}
                  <span>({{ selectedSources.length }})</span>
                </button>
              </li>
              <li
                v-if="isCriticalLevelEnabled"
                :class="{ selected: selectedFilter === 'risk' }"
              >
                <button @click="selectedFilter = 'risk'">
                  {{ $t('REPORT_SUBHEADER_RISK') }}
                  <span>({{ selectedCriticalLevel.length }})</span>
                </button>
              </li>
              <li class="reset-button">
                <button @click="resetFilters">
                  {{ $t('REMOVE_FILTERS') }}
                </button>
              </li>
              <button class="close-button" @click.prevent="closeFilters">
                <img :src="iconClose" alt="Fechar" />
              </button>
            </menu>

            <div
              v-if="selectedFilter === 'status' && showStatusFilter"
              class="status"
            >
              <ul>
                <li v-for="status in STATUSES" :key="status">
                  <s-status
                    :label="status"
                    :class="[{ outline: !statusFilter.includes(status) }]"
                    @click="filterReportByStatus(status)"
                  />
                </li>
              </ul>
            </div>

            <div class="container">
              <template v-if="selectedFilter === 'areas'">
                <FilterOptions
                  v-for="area in areas"
                  :key="area.id"
                  v-model="selectedAreas"
                  :input-value="area.id"
                  :label="area[validLocale]"
                />
              </template>

              <template
                v-if="selectedFilter === 'branches' && isCompanyBranchEnabled"
              >
                <FilterOptions
                  v-for="branch in branches"
                  :key="branch.key"
                  v-model="selectedBranches"
                  :input-value="branch.key"
                  :label="branch[validLocale]"
                />
              </template>

              <template
                v-if="selectedFilter === 'risk' && isCriticalLevelEnabled"
              >
                <FilterOptions
                  v-for="(risk, index) in risks"
                  :key="index"
                  v-model="selectedCriticalLevel"
                  :input-value="risk"
                  :label="risk + ' - ' + $t(riskLabel[index])"
                />
              </template>

              <template v-if="selectedFilter === 'misconducts'">
                <FilterOptions
                  v-for="misconduct in misconducts"
                  :key="misconduct.id"
                  v-model="selectedMisconducts"
                  :input-value="misconduct.localeKey"
                  :label="$t(misconduct.localeKey)"
                />
              </template>

              <template v-if="selectedFilter === 'source'">
                <FilterOptions
                  v-for="source in sources"
                  :key="source.key"
                  v-model="selectedSources"
                  :input-value="source.key"
                  :label="source[validLocale]"
                />
              </template>
            </div>
          </div>
        </div>
        <Toggle v-if="isBoardViewEnabled" v-on="$listeners">
          <template #table>{{ $t('TABLE_VIEW') }}</template>
          <template #board>{{ $t('BOARD_VIEW') }}</template>
        </Toggle>
      </div>
      <div class="pagination">
        <s-paginate
          v-if="pagination?.lastPage"
          :pages="pagination?.lastPage"
          @next="paginate"
          @previous="paginate"
        />
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import pull from 'lodash/pull'

import { STATUSES } from '@/utils/constants'
import iconClose from '@/assets/images/icon_close.svg'
import { sStatus, sPaginate } from '@/components/ui'
import Toggle from './Toggle.vue'
import FilterOptions from './FilterOptions.vue'

import iconArrowDown from '@/assets/images/icon_arrow_down_LIGHT.svg'
import iconArrowUp from '@/assets/images/icon_arrow_up_LIGHT.svg'
import iconSend from '@/assets/images/icon_send.svg'

export default {
  name: 'Header',
  components: {
    sStatus,
    sPaginate,
    Toggle,
    FilterOptions,
  },

  props: {
    pagination: {
      type: Object,
      required: false,
      default: null,
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },

    validLocale: {
      type: String,
      required: true,
    },

    showStatusFilter: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      iconArrowDown,
      iconArrowUp,
      iconSend,
      iconClose,
      STATUSES,
      term: '',
      showFilters: false,
      selectedAreas: [],
      selectedBranches: [],
      selectedMisconducts: [],
      selectedSources: [],
      selectedCriticalLevel: [],
      statusFilter: [],
      page: null,
      maxLength: 15,
      selectedFilter: null,
      sources: [],
      risks: [1, 2, 3, 4],
      riskLabel: ['RISK_LOW', 'RISK_MEDIUM', 'RISK_HIGH', 'RISK_CRITICAL'],
    }
  },

  computed: {
    ...mapGetters([
      'isBoardViewEnabled',
      'isCompanyBranchEnabled',
      'isCriticalLevelEnabled',
      'enabledRelations',
    ]),
    ...mapState(['areas', 'misconducts', 'branches']),

    defaultSelectedFilter() {
      return this.showStatusFilter ? 'status' : 'misconducts'
    },

    compoundFilters() {
      return (
        this.selectedAreas,
        this.selectedBranches,
        this.selectedMisconducts,
        this.selectedSources,
        this.selectedCriticalLevel,
        this.statusFilter,
        Date.now()
      )
    },
  },

  watch: {
    showStatusFilter() {
      this.resetFilters()
    },

    compoundFilters() {
      this.filterReports()
    },
  },

  async created() {
    this.selectedFilter = this.defaultSelectedFilter
    if (!this.misconducts.length) {
      this.getMisconductsWithOther()
    }
    if (this.isCompanyBranchEnabled && !this.branches.length) {
      this.getCompanyBranches()
    }
    this.sources = this.enabledRelations
  },

  methods: {
    ...mapActions(['getMisconductsWithOther', 'getCompanyBranches']),

    paginate(page) {
      this.page = page
      this.filterReports()
    },

    async filterReportByStatus(status) {
      if (this.statusFilter.includes(status)) {
        pull(this.statusFilter, status)
      } else {
        this.statusFilter.push(status)
      }

      this.filterReports()
    },

    resetFilters() {
      this.term = ''
      this.statusFilter = []
      this.selectedAreas = []
      this.selectedBranches = []
      this.selectedMisconducts = []
      this.selectedSources = []
      this.selectedCriticalLevel = []
    },

    filterReports() {
      const {
        term,
        page,
        statusFilter,
        selectedAreas,
        selectedBranches,
        selectedMisconducts,
        selectedSources,
        selectedCriticalLevel,
      } = this
      this.$emit('filterReports', {
        term,
        page,
        selectedAreas,
        selectedBranches,
        selectedMisconducts,
        selectedSources,
        selectedCriticalLevel,
        statusFilter,
      })
    },

    closeFilters() {
      this.showFilters = false
    },
  },
}
</script>

<style scoped>
.header {
  align-items: center;
  background: var(--color-white);
  width: calc(100% - var(--sidebar-open-width));
}

.dashboard.collapsed .header {
  width: calc(100% - var(--sidebar-closed-width));
}

.header__top .container,
.header__bottom .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard.collapsed .header__top {
  width: calc(100vw - var(--sidebar-closed-width) - var(--header-spacing-2));
}

.header__top {
  width: calc(100vw - var(--sidebar-open-width) - var(--header-spacing-2));
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid var(--color-grey-lighter);
}

.header__bottom {
  margin-right: 122px;
}

.header__bottom .container {
  justify-content: flex-end;
}

.header .report__total {
  display: flex;
  align-items: flex-end;

  padding: var(--header-spacing-1) var(--header-spacing-1)
    var(--header-spacing-1) 0;
  margin: 0 var(--header-spacing-1) 0 var(--header-spacing-3);
  border-right: 0.1rem solid var(--color-grey-lighter);
}

.header .report__total h1 {
  font-size: var(--font-size-large);
  font-weight: 300;
  margin: 0;
}

.header .report__total span {
  font-size: var(--font-size-4x);
  color: var(--color-pink-default);
  font-weight: 500;
  margin-left: 5px;
}

.header .header__search {
  margin-left: var(--header-spacing-1);
  position: relative;
  align-self: center;
}

.header .header__search input {
  height: 33px;
  margin-bottom: 0;
  font-size: var(--font-size-default);
  padding: 1.7rem 2rem;
  width: 280px;
  max-width: 280px;
  margin-right: 1rem;
  border: 0.5px solid var(--color-grey-light);
}

.header .header__search button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 6px;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.header .header__search button img {
  height: 27px;
}

.header .header__filters {
  display: flex;
  align-self: center;
  align-items: center;
  padding-left: 1rem;
  flex-grow: 1;
  height: 100%;
}

.header__filters.enabled {
  flex-wrap: wrap;
  position: relative;
}

.header__filters .toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  background: none;
  border: none;
  color: var(--color-grey-default);
  font-family: 'FieldWork', Avenir, Helvetica, Arial, sans-serif;
  font-size: var(--font-size-default);
  font-weight: 300;
}

.header__filters .toggle img {
  margin-left: 5px;
}

.header__filters .content.enabled {
  display: block;
}

.header__filters .content .container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-height: 400px;
  overflow: hidden scroll;
}

.header__filters .content {
  display: none;
  background-color: white;
  width: 55%;
  border-radius: var(--border-radius-default);
  box-shadow: 0px 8px 9px #70707014;
  padding: 2rem 4rem;
  border: 1px solid var(--color-grey-lighter);
  align-self: flex-start;
  position: absolute;
  left: 25%;
  top: 140px;
  z-index: 999;
}

.header__filters .content menu {
  display: flex;
  list-style: none;
  padding: 0;
}

.header__filters .content menu li {
  padding-right: 10px;
}

.header__filters .content menu li button {
  background: none;
  border: none;
  font-weight: 500;
}

.header__filters .content menu li.reset-button {
  margin-left: auto;
}

.header__filters .content menu li.selected button {
  color: var(--color-pink-default);
  border-bottom: 2px solid var(--color-pink-default);
}

.header__filters .content menu li button:hover {
  cursor: pointer;
}

.header__filters .content .area {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.header__filters .content .status {
  flex-grow: 3;
}

.header__filters .content .status ul {
  padding: 0;
}

.header__filters .content .status ul li {
  list-style: none;
  padding: 0;
  margin: 25px 0;
}

.header .pagination {
  width: 122px;
}

.header .loader {
  margin: auto;
  margin-left: 5px;
  border: 3px solid;
  border-color: var(--color-pink-default) var(--color-pink-default)
    var(--color-white) var(--color-white);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  -webkit-animation: spinner-66eafea6 4s linear infinite;
  animation: spinner-66eafea6 1s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
