import { apolloClient as graphql } from '@/apolloProvider'

import { MODERATE_ADMIN } from '@/graphql/mutations'

import { GET_PENDING_ADMINS } from '@/graphql/queries'

export default {
  moderateAdmin: async (context, params) => {
    await graphql.mutate({
      mutation: MODERATE_ADMIN,
      variables: { auditId: params.auditId, decision: params.decision },
    })
  },

  getPendingAdmins: async () => {
    const {
      data: { getPendingAdmins },
    } = await graphql.query({
      query: GET_PENDING_ADMINS,
    })

    return getPendingAdmins
  },
}
