export default {
  name: 's-button',

  props: {
    btnStyle: {
      type: String,
      required: false,
      default: 'primary',
    },
    flat: {
      type: Boolean,
      required: false,
      default: false,
    },
    linkTo: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'button',
    },
  },

  computed: {
    isFlat() {
      return this.flat ? 'flat' : ''
    },
  },
}
