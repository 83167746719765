import Logger from '@/plugins/logger'
import i18n from '@/plugins/i18n'
import { LOCAL_STORAGE_KEYS } from '@/globals'

const mutations = {
  setReports: (state, reports) => {
    state.reports = reports

    const newOnes = reports.filter(({ status }) => status === 'NEW')

    const { counters } = state

    state.counters = {
      ...counters,
      reports: {
        new: newOnes.length,
        all: reports.pagination?.total,
      },
    }
  },
  setReport: (state, report) => {
    state.report = report
    state.code = report.code
    state.reportId = report.id
  },
  setNewNote: (state, newNote) => (state.notes = [...state.notes, newNote]),
  updateNoteByMessageAndTimestamp: (state, newNote) => {
    const { notes } = state
    const { message: noteMessage, createdAt: noteCreatedAt } = newNote

    const foundNoteIndex = notes.findIndex((currentNote) => {
      const { message, createdAt } = currentNote
      return (
        message === noteMessage && createdAt.seconds === noteCreatedAt.seconds
      )
    })

    const isNotIndex = (searchIndex) => {
      return (_, currentIndex) => currentIndex !== searchIndex
    }

    return [notes.filter(isNotIndex(foundNoteIndex)), { ...newNote }]
  },
  setNotes: (state, notes) => (state.notes = [...notes]),
  setCompany: (state, company) => (state.company = company),
  setCompanyId: (state, companyId) => (state.companyId = companyId),
  setBranches: (state, branches) => (state.branches = branches),
  setCurrentUser: (state, currentUser) => (state.currentUser = currentUser),
  setReplies: (state, payload) => (state.replies = payload),
  setLocale: (state, language) => {
    state.locale = localStorage.getItem('locale') || language?.substring(0, 2)
    i18n.locale = state.locale
  },
  setLoading: (state, loading) => (state.loading = loading),
  setError: (state, error) => {
    state.error = error
    state.loading = false

    if (error) {
      Logger.debug(error?.message || error?.code || error, {
        user: state?.account,
        company: state?.company,
        tenant: state?.tenant,
        tags: [['mutation', 'set-error']],
      })
    }
  },

  clearGraphQLError: (state) => {
    state.error = null
  },

  setGraphQLError: (state, errors = []) => {
    if (!errors) {
      state.error = null
      return
    }

    const hasGraphQLErrors = {}.hasOwnProperty.call(errors, 'graphQLErrors')

    if (!hasGraphQLErrors) {
      state.error = null
      return
    }

    const graphQLErrors = errors['graphQLErrors'] || []

    graphQLErrors.forEach(({ message }) => {
      state.error = message

      Logger.debug(message, {
        tags: [['service', 'report-api']],
        user: state?.account,
        context: state?.company,
      })
    })
  },

  setNotice: (state, notice) => (state.notice = notice),
  setPermissions: (state, permissions) => {
    state.permissions = permissions
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.PERMISSIONS,
      JSON.stringify(permissions),
    )
  },
  setAssignMisconducts: (state, assignMisconducts) =>
    (state.assignMisconducts = assignMisconducts),
  setMisconducts: (state, misconducts) => (state.misconducts = misconducts),
  setReportAssignees: (state, reportAssignees) =>
    (state.reportAssignees = reportAssignees),
  mutate: (state, payload) => (state[payload.property] = payload.value),
}

export default mutations
