const COMPANY_ID = 'ssa:cid'
const CONFIG = 'ssa:cnf'
const FEATURES = 'ssa:ft'
const EMPLOYEE_ID = 'ssa:eid'
const TENANT = 'ssa:client'
const TOKEN = 'ssa:token'
const PERMISSIONS = 'ssa:perms'
const RECAPTCHA_ENTERPRISE_KEY =
  process.env.VUE_APP_RECAPTCHA_ENTERPRISE_CLIENT_KEY

const LOCAL_STORAGE_KEYS = {
  COMPANY_ID,
  CONFIG,
  FEATURES,
  EMPLOYEE_ID,
  TENANT,
  TOKEN,
  PERMISSIONS,
}

const AUTHENTICATION_LOCAL_STORAGE_KEYS = Object.values(LOCAL_STORAGE_KEYS)

export {
  EMPLOYEE_ID,
  COMPANY_ID,
  CONFIG,
  FEATURES,
  TENANT,
  TOKEN,
  RECAPTCHA_ENTERPRISE_KEY,
  LOCAL_STORAGE_KEYS,
  AUTHENTICATION_LOCAL_STORAGE_KEYS,
  PERMISSIONS,
}
