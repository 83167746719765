import { DB, Timestamp } from '@/plugins/firebase'

import { sendMessageToSlack } from '@/plugins/slack'

const actions = {
  async createSupport({ commit, state, dispatch }, payload) {
    await dispatch('getCompanyByTenant')

    payload.companyRef = DB.collection('companies').doc(state.companyId)
    payload.employeeId = state.adminId
    payload.email = state.account.email
    payload.username = state.account?.name
    payload.companyName = state.company?.name
    payload.sentAt = Timestamp.fromDate(new Date())
    payload.status = 'NEW'

    DB.collection('support')
      .add(payload)
      .then(() => {
        commit('setError', null)
        sendMessageToSlack(payload)
      })
      .catch((err) => commit('setError', err.code))
  },

  async exportDataRequest({ dispatch }) {
    const support = {
      subject: 'DATA_EXPORT',
      message: 'Solicito relatório com análise de dados.',
    }
    await dispatch('createSupport', support)
  },

  async getSupportRequest({ commit, state }, subject) {
    const companyRef = DB.collection('companies').doc(state.companyId)

    const result = await DB.collection('support')
      .where('companyRef', '==', companyRef)
      .where('subject', '==', subject)
      .where('status', '==', 'NEW')
      .get()
      .then(({ docs, empty }) => ({ docs, empty }))
      .catch((err) => commit('setError', err.code))

    return result
  },
}

export default actions
