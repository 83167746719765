import { apolloClient as graphql } from '@/apolloProvider'

import {
  FIND_EMPLOYEE,
  GET_EMPLOYEES,
  GET_ACCOUNT,
  GET_ADMIN_ASSIGNEES,
} from '@/graphql/queries'

import {
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_AREA,
  UPDATE_ACCOUNT,
} from '@/graphql/mutations'

import { groupPermissions, CAN_UPDATE_USERS } from '@/store/permissions'
import Logger from '@/plugins/logger'

export default {
  getEmployees: async (
    { commit, dispatch },
    { page = 1, term, active, admin, sortBy, areas } = {},
  ) => {
    await dispatch('checkPermission', groupPermissions.USERS)

    const {
      data: { employees },
    } = await graphql.query({
      query: GET_EMPLOYEES,
      variables: { page, term, active, admin, sortBy, areas },
      fetchPolicy: 'no-cache',
    })

    commit('mutate', {
      property: 'employees',
      value: { ...employees.data, total: employees.pagination.total },
    })

    return employees
  },

  getEmployee: async (_, employeeId) => {
    const result = await graphql.query({
      query: FIND_EMPLOYEE,
      variables: { id: employeeId },
      fetchPolicy: 'no-cache',
    })

    if (result?.data) {
      return result.data?.employee
    }

    return {}
  },

  createEmployee: async ({ commit, dispatch }, params) => {
    await dispatch('checkPermission', CAN_UPDATE_USERS)

    commit('setNotice', null)
    commit('setLoading', true)
    commit('setError', null)

    const { name, email, language } = params

    const { data } = await graphql
      .mutate({
        mutation: CREATE_EMPLOYEE,
        variables: { name, email, language },
        refetchQueries: ['employees'],
      })
      .catch((err) => commit('setError', err))
      .finally(commit('setLoading', false))

    return data?.createEmployee
  },

  updateEmployee: async ({ dispatch }, payload) => {
    await dispatch('checkPermission', CAN_UPDATE_USERS)

    const { id, active } = payload
    await graphql.mutate({
      mutation: UPDATE_EMPLOYEE,
      variables: { id, active },
    })
  },

  updateEmployeeArea: async (_, payload) => {
    const { id, areaId } = payload
    await graphql.mutate({
      mutation: UPDATE_EMPLOYEE_AREA,
      variables: { id, areaId },
    })
  },

  getAccount: async ({ commit, state }) => {
    try {
      const response = await graphql
        .query({
          query: GET_ACCOUNT,
          fetchPolicy: 'no-cache',
        })
        .catch((error) => {
          Logger.debug(error?.message, {
            tags: [['action', 'employees/get-account']],
            user: { uid: state.currentUser, companyId: state.companyId },
          })
        })

      const account = response?.data?.account

      commit('mutate', { property: 'account', value: account })
      commit('setPermissions', account?.permissions || [])

      return account
    } catch (error) {
      Logger.warn(error?.message || 'Error on get user account', {
        tags: [['action', 'employees/get-account']],
        user: { uid: state.currentUser, companyId: state.companyId },
      })
    }
  },

  updateAccount: async (_, payload) => {
    const { id, name, language } = payload
    await graphql.mutate({
      mutation: UPDATE_ACCOUNT,
      variables: { id, name, language },
    })
  },

  getAdminAssignees: async (_, reportId) => {
    const { data } = await graphql.query({
      query: GET_ADMIN_ASSIGNEES,
      variables: { reportId },
      fetchPolicy: 'no-cache',
    })

    return data?.adminsAssignees
  },

  getIndexedEmployees: async (_, employeesIds) => {
    const { data } = await graphql.query({
      query: GET_EMPLOYEES,
      variables: { ids: employeesIds },
    })

    const employees = {}

    data.employees.data.map((employee) => {
      employees[employee.id] = employee
    })

    return employees
  },
}
