<template :key="key">
  <section class="reports">
    <ReportsHeader
      v-if="!loading"
      :total="total"
      :valid-locale="validLocale"
      :show-status-filter="!isBoard"
      :pagination="!isBoard ? tablePagination : null"
      @filterReports="filterReports"
      @toggleView="toggleView"
    />

    <div v-if="!loading && total === 0" class="dashboard__empty">
      <div class="caption">
        <h3>{{ $t('DASHBOARD_EMPTY_TITLE') }}</h3>
        <p>{{ $t('DASHBOARD_EMPTY_TEXT_INTRO') }}</p>
        <p>{{ $t('DASHBOARD_EMPTY_TEXT_QUESTION') }}</p>
        <p>
          {{ $t('DASHBOARD_EMPTY_TEXT_FAQ') }}
          <router-link to="/faq">
            {{ $t('DASHBOARD_EMPTY_TEXT_FAQ_LINK') }}
          </router-link>
        </p>
      </div>
      <div>
        <img :src="dashboardBgEmpty" alt="Safe Community" />
      </div>
    </div>

    <div v-else>
      <BoardView
        v-if="isBoard"
        :status-list="statusList"
        :per-page="perPage"
        :filter-options="filterOptions"
        @updateTotal="updateTotal"
      />
      <TableView
        v-else
        :filter-options="filterOptions"
        @paginate="(pagination) => (tablePagination = pagination)"
        @updateTotal="updateTotal"
      />
      <Portal to="root">
        <v-tour
          v-if="displayReportsTour"
          name="board"
          :steps="isBoard ? steps : steps.slice(0, 2)"
          :callbacks="tourCallbacks"
          :options="tourOptions"
        />
      </Portal>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import {
  STATUSES as statusList,
  PER_PAGE_BOARD as perPage,
} from '@/utils/constants'

import ReportsHeader from '@/components/Reports/Header'

import dashboardBgEmpty from '@/assets/images/dashboard_empty.svg'
import { groupPermissions } from '@/store/permissions'

export default {
  name: 'Reports',

  components: {
    ReportsHeader,
    BoardView: () => import('@/components/Reports/Board/'),
    TableView: () => import('@/components/Reports/Table/'),
  },

  beforeRouteEnter(__, _from, next) {
    next((vm) => {
      const isFeatureEnabled = vm.$store.getters.isReportingChannelEnabled
      if (!isFeatureEnabled) {
        next('/settings')
      }
    })
  },

  props: {
    groupId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      key: this.$route.path,
      statusList,
      perPage,
      dashboardBgEmpty,
      total: -1,
      loading: true,
      filterOptions: {},
      tablePagination: {},
      display: null,
      steps: [
        {
          target: '[data-v-step="0"]',
          content: this.$t('STEP_0'),
          params: {
            placement: 'bottom',
          },
        },
        {
          target: '[data-v-step="1"]',
          content: this.$t('STEP_1'),
          params: {
            placement: 'bottom',
          },
        },
        {
          target: '[data-v-step="2"]',
          content: this.$t('STEP_2'),
          params: {
            placement: 'bottom',
          },
        },
        {
          target: '[data-v-step="3"]',
          content: this.$t('STEP_3'),
          params: {
            placement: 'bottom',
          },
        },
        {
          target: '[data-v-step="4"]',
          content: this.$t('STEP_4'),
          params: {
            placement: 'bottom',
          },
        },
        {
          target: '[data-v-step="4"]',
          content: this.$t('STEP_5'),
          params: {
            placement: 'bottom',
          },
        },
      ],
      tourOptions: {
        highlight: true,
        labels: {
          buttonSkip: this.$t('SKIP'),
          buttonPrevious: this.$t('PREVIOUS'),
          buttonNext: this.$t('NEXT'),
          buttonStop: this.$t('STOP'),
        },
      },
      tourCallbacks: {
        onFinish: this.finishTour,
        onStop: this.finishTour,
      },
    }
  },

  computed: {
    ...mapState(['account', 'company', 'locale', 'error']),
    ...mapGetters(['isBoardViewEnabled', 'isInvestigationBotEnabled']),

    validLocale() {
      return this.locale === 'pt-BR' ? 'pt' : this.locale
    },

    isBoard() {
      if (!this.isBoardViewEnabled) return false
      return this.display === 'board'
    },

    displayReportsTour() {
      return !JSON.parse(localStorage.getItem('_srt')) // _srt: seen reports tour
    },
  },

  watch: {
    error() {
      this.$toasted.show(this.$t(this.error), { type: 'error' })
    },
  },

  async created() {
    await this.checkPermission(groupPermissions.REPORT)
  },

  async mounted() {
    this.filterOptions = {
      ...this.filterOptions,
      groups: this.groupId ? [this.groupId] : [],
    }
    this.getCompanyAreas()

    this.display = this.isBoardViewEnabled
      ? localStorage.getItem('_rd') || 'board'
      : 'table'

    if (this.displayReportsTour) {
      this.$tours['board']?.start()
    }

    if (this.isInvestigationBotEnabled) {
      if (window?.octadesk?.chat.isReady()) {
        window.octadesk.chat.login({
          name: this.account.name,
          email: this.account.email,
        })
        window.octadesk.chat?.setOrganization('Company', this.company.name)
        window.octadesk.chat?.show()
        window.octadesk.chat?.showButton()
      }
    }
  },

  beforeDestroy() {
    window.octadesk.chat.hideButton()
    window.octadesk.chat.hideApp()
  },

  methods: {
    ...mapActions(['getCompanyAreas', 'checkPermission']),

    filterReports(options) {
      this.total = -1
      const {
        term,
        page,
        statusFilter: statuses,
        selectedAreas: areas,
        selectedSources: sources,
        selectedMisconducts: misconductLocaleKeys,
        selectedBranches: companyBranches,
        selectedCriticalLevel: criticalLevels,
      } = options
      this.filterOptions = {
        term,
        page,
        statuses,
        areas,
        sources,
        misconductLocaleKeys,
        companyBranches,
        criticalLevels,
        groups: this.groupId ? [this.groupId] : [],
      }
    },

    toggleView(display) {
      this.display = display
    },

    updateTotal(value) {
      this.tablePagination = value
      this.total = value.total
      this.loading = false
    },

    finishTour() {
      localStorage.setItem('_srt', JSON.stringify(true))
    },
  },
}
</script>

<style scoped>
.reports main {
  padding-top: 15px;
}

@media (max-width: 1440px) {
  .reports main {
    padding: 10px 40px 0;
  }
}

@media (max-width: 1366px) {
  .reports main {
    padding: 10px 10px 0;
  }
}
.reports main >>> .modal.solve-report .modal-card {
  top: 35%;
}

.dashboard__empty {
  margin: 25px auto 0;
  padding: 5rem;
  text-align: center;
}

.dashboard__empty div {
  width: 100%;
}

.dashboard__empty .caption {
  margin-top: 8rem;
}

.dashboard__empty .caption h3 {
  display: block;
  font-weight: 600;
  font-size: 18px;
}

.dashboard__empty .caption p {
  margin: 0;
}

.dashboard__empty .caption a {
  font-weight: 600;
}

.dashboard__empty img {
  height: 45rem;
  margin-top: 8rem;
}
</style>
