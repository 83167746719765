import gql from 'graphql-tag'

import assignReportPermission from './ASSIGN_REPORT_PERMISSION.gql'
import revokeReportPermission from './REVOKE_REPORT_PERMISSION.gql'
import createAgreement from './CREATE_AGREEMENT.gql'
import createArea from './CREATE_AREA.gql'
import createEmployee from './CREATE_EMPLOYEE.gql'
import solveReport from './SOLVE_REPORT.gql'
import updateArea from './UPDATE_AREA.gql'
import updateEmployeeArea from './UPDATE_EMPLOYEE_AREA.gql'
import updateEmployee from './UPDATE_EMPLOYEE.gql'
import updateAccount from './UPDATE_ACCOUNT.gql'
import updateReportStatus from './UPDATE_STATUS_REPORT.gql'
import assignReportMisconducts from './ASSIGN_REPORT_MISCONDUCTS.gql'
import unassignReportMisconducts from './UNASSIGN_REPORT_MISCONDUCTS.gql'
import moderateAdmin from './MODERATE_ADMIN.gql'
import createReport from './CREATE_REPORT.gql'
import createReportAdditionalData from './CREATE_REPORT_ADDITIONAL_DATA.gql'
import assignReportAreas from './ASSIGN_REPORT_AREAS.gql'
import unassignReportAreas from './UNASSIGN_REPORT_AREAS.gql'
import attachInvolvedToReport from './ATTACH_REPORT_INVOLVED.gql'
import detachReportFromReport from './DETACH_REPORT_INVOLVED.gql'
import updateCriticalityLevel from './UPDATE_CRITICALITY_LEVEL.gql'
import moveReportsToGroups from './MOVE_REPORTS_TO_GROUPS.gql'
import removeReportsFromGroups from './REMOVE_REPORTS_FROM_GROUPS.gql'
import updateCompanyBranchReport from './UPDATE_COMPANY_BRANCH_REPORT.gql'

const ASSIGN_REPORT_PERMISSION = gql`
  ${assignReportPermission}
`

const REVOKE_REPORT_PERMISSION = gql`
  ${revokeReportPermission}
`

const CREATE_AGREEMENT = gql`
  ${createAgreement}
`

const CREATE_AREA = gql`
  ${createArea}
`

const CREATE_EMPLOYEE = gql`
  ${createEmployee}
`

const SOLVE_REPORT = gql`
  ${solveReport}
`

const UPDATE_EMPLOYEE = gql`
  ${updateEmployee}
`

const UPDATE_EMPLOYEE_AREA = gql`
  ${updateEmployeeArea}
`

const UPDATE_ACCOUNT = gql`
  ${updateAccount}
`

const UPDATE_AREA = gql`
  ${updateArea}
`

const UPDATE_STATUS_REPORT = gql`
  ${updateReportStatus}
`

const ASSIGN_REPORT_MISCONDUCTS = gql`
  ${assignReportMisconducts}
`

const UNASSIGN_REPORT_MISCONDUCTS = gql`
  ${unassignReportMisconducts}
`

const MODERATE_ADMIN = gql`
  ${moderateAdmin}
`

const CREATE_REPORT = gql`
  ${createReport}
`

const CREATE_REPORT_ADDITIONAL_DATA = gql`
  ${createReportAdditionalData}
`

const ASSIGN_REPORT_AREAS = gql`
  ${assignReportAreas}
`

const UNASSIGN_REPORT_AREAS = gql`
  ${unassignReportAreas}
`

const ATTACH_REPORT_INVOLVED = gql`
  ${attachInvolvedToReport}
`

const DETACH_REPORT_INVOLVED = gql`
  ${detachReportFromReport}
`

const UPDATE_CRITICALITY_LEVEL = gql`
  ${updateCriticalityLevel}
`

const MOVE_REPORTS_TO_GROUPS = gql`
  ${moveReportsToGroups}
`

const REMOVE_REPORTS_FROM_GROUPS = gql`
  ${removeReportsFromGroups}
`

const UPDATE_COMPANY_BRANCH_REPORT = gql`
  ${updateCompanyBranchReport}
`

export {
  ASSIGN_REPORT_PERMISSION,
  REVOKE_REPORT_PERMISSION,
  CREATE_AGREEMENT,
  CREATE_AREA,
  CREATE_EMPLOYEE,
  SOLVE_REPORT,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_AREA,
  UPDATE_ACCOUNT,
  UPDATE_AREA,
  UPDATE_STATUS_REPORT,
  ASSIGN_REPORT_MISCONDUCTS,
  UNASSIGN_REPORT_MISCONDUCTS,
  MODERATE_ADMIN,
  CREATE_REPORT,
  CREATE_REPORT_ADDITIONAL_DATA,
  ASSIGN_REPORT_AREAS,
  UNASSIGN_REPORT_AREAS,
  ATTACH_REPORT_INVOLVED,
  DETACH_REPORT_INVOLVED,
  UPDATE_CRITICALITY_LEVEL,
  MOVE_REPORTS_TO_GROUPS,
  REMOVE_REPORTS_FROM_GROUPS,
  UPDATE_COMPANY_BRANCH_REPORT,
}
