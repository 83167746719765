import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import i18n from './plugins/i18n'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Toasted from 'vue-toasted'
import router from './plugins/router'
import 'es6-promise/auto'
import PortalVue from 'portal-vue'
import VueTour from 'vue-tour'
import ToggleButton from 'vue-js-toggle-button'

import store from './store'
import apolloProvider from './apolloProvider'

require('vue-tour/dist/vue-tour.css')

const toastrOptions = {
  theme: 'toasted-primary',
  position: 'top-center',
  duration: 5000,
  containerClass: 'ss-alert',
  keepOnHover: true,
}

Vue.use(VueMaterial)
Vue.use(Vuex)
Vue.use(Toasted, toastrOptions)
Vue.use(require('vue-moment'))
Vue.use(PortalVue)
Vue.use(VueTour)
Vue.use(ToggleButton)

Vue.filter('percentage', function (value, total) {
  if (!value || !total) return ''
  return Math.round((value / total) * 100)
})

Vue.filter('truncate', function (value, maxLength) {
  if (value.length > maxLength) {
    return value.substring(0, maxLength) + '...'
  } else {
    return value
  }
})

new Vue({
  i18n,
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app')

Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.debug = process.env.NODE_ENV !== 'production'
Vue.config.silent = process.env.NODE_ENV === 'production'
