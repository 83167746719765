<template>
  <button
    :class="['s-status', label.toLowerCase()]"
    :disabled="disabled"
    v-on="$listeners"
  >
    {{ $t(label) }}
  </button>
</template>

<script>
export default {
  name: 'SStatus',

  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
.s-status {
  border: 0.22rem transparent solid;
  border-radius: 2rem;
  color: var(--color-white);
  font-family: 'FieldWork', Avenir, Helvetica, Arial, sans-serif;
  font-size: var(--font-size-small);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-default);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding: 0.3rem 0.1rem;
  width: 13rem;
  cursor: pointer;
}

.s-status.outline {
  border: 0.22rem solid;
  background-color: var(--color-white) !important;
  color: var(--color-pink-default);
}

.s-status.new {
  background-color: var(--color-pink-default);
  color: var(--color-white);
}
.s-status.outline.new {
  color: var(--color-pink-default);
}

.s-status.open,
.s-status.opened,
.s-status.disclosure_status_open {
  background-color: var(--color-blue-default);
  color: var(--color-white);
}
.s-status.outline.open,
.s-status.outline.opened,
.s-status.outline.disclosure_status_open {
  color: var(--color-blue-default);
}

.s-status.answered,
.s-status.under_review {
  background-color: var(--color-yellow-default);
  color: var(--color-white);
}
.s-status.outline.answered,
.s-status.outline.under_review {
  color: var(--color-yellow-default);
}

.s-status.processing {
  background-color: var(--color-grey-light);
  color: var(--color-white);
}

.s-status.pending {
  background-color: var(--color-pink-light);
  color: var(--color-white);
}
.s-status.outline.pending {
  color: var(--color-pink-light);
}

.s-status.solved,
.s-status.completed,
.s-status.disclosure_status_solved {
  background-color: var(--color-green-default);
  color: var(--color-white);
}
.s-status.outline.solved,
.s-status.outline.completed,
.s-status.outline.disclosure_status_solved {
  color: var(--color-green-default);
}

.s-status.error {
  background-color: var(--color-red-default);
  color: var(--color-white);
}

.s-status.outline.error {
  color: var(--color-red-default);
}

.s-status.outline.PUBLISHED {
  color: var(--color-pink-default);
}

.s-status.outline.ARCHIVED {
  color: var(--color-black);
}

.s-status.outline.DRAFT {
  color: var(--color-grey-light);
}
</style>
