import { FEATURES } from '@/globals'

const DISCLOSURES = 'disclosure'
const BOARD_VIEW = 'boardView'

const checkFeaturesEnabled = (features) => {
  const companyFeatures = JSON.parse(localStorage.getItem(FEATURES))
  return features.every((feature) => companyFeatures[feature])
}

const features = {
  DISCLOSURES,
  BOARD_VIEW,
}

export { features, checkFeaturesEnabled }
