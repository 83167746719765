import gql from 'graphql-tag'

import getAreas from './GET_AREAS.gql'
import findEmployee from './FIND_EMPLOYEE.gql'
import getEmployees from './GET_EMPLOYEES.gql'
import getAccount from './GET_ACCOUNT.gql'
import getReports from './GET_REPORTS.gql'
import getReport from './GET_REPORT.gql'
import getReportAssignees from './GET_REPORT_ASSIGNEES.gql'
import getAdminAssignees from './GET_ADMIN_ASSIGNEES.gql'
import getMisconducts from './GET_MISCONDUCTS.gql'
import getReportMisconducts from './GET_REPORT_MISCONDUCTS.gql'
import getAnalyticsReports from './GET_ANALYTICS_REPORTS.gql'
import getRelatedReports from './GET_RELATED_REPORTS.gql'
import getReportCode from './GET_REPORT_CODE.gql'
import getPendingAdmins from './GET_PENDING_ADMINS.gql'
import getGroups from './GET_GROUPS.gql'

const GET_GROUPS = gql`
  ${getGroups}
`

const GET_AREAS = gql`
  ${getAreas}
`

const FIND_EMPLOYEE = gql`
  ${findEmployee}
`

const GET_EMPLOYEES = gql`
  ${getEmployees}
`

const GET_ACCOUNT = gql`
  ${getAccount}
`

const GET_REPORTS = gql`
  ${getReports}
`

const GET_REPORT = gql`
  ${getReport}
`

const GET_REPORT_ASSIGNEES = gql`
  ${getReportAssignees}
`

const GET_ADMIN_ASSIGNEES = gql`
  ${getAdminAssignees}
`

const GET_MISCONDUCTS = gql`
  ${getMisconducts}
`

const GET_REPORT_MISCONDUCTS = gql`
  ${getReportMisconducts}
`

const GET_ANALYTICS_REPORTS = gql`
  ${getAnalyticsReports}
`

const GET_RELATED_REPORTS = gql`
  ${getRelatedReports}
`

const GET_REPORT_CODE = gql`
  ${getReportCode}
`

const GET_PENDING_ADMINS = gql`
  ${getPendingAdmins}
`

export {
  GET_AREAS,
  GET_EMPLOYEES,
  GET_ACCOUNT,
  GET_REPORTS,
  GET_REPORT,
  GET_REPORT_ASSIGNEES,
  GET_ADMIN_ASSIGNEES,
  GET_MISCONDUCTS,
  GET_REPORT_MISCONDUCTS,
  GET_ANALYTICS_REPORTS,
  GET_RELATED_REPORTS,
  GET_REPORT_CODE,
  FIND_EMPLOYEE,
  GET_PENDING_ADMINS,
  GET_GROUPS,
}
