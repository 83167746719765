import Vue from 'vue'
import Vuex from 'vuex'
import Logger from '../plugins/logger'
import router from '../plugins/router'

import state from './state'
import getters from './getters'
import mutations from './mutations'

// ReportAPI
import areasActions from './actions/report-api/areas.actions'
import reportsActions from './actions/report-api/reports.actions'
import employeesActions from './actions/report-api/employees.actions'
import misconductsActions from './actions/report-api/misconducts.actions'
import analyticsActions from './actions/report-api/analytics.actions'
import moderateAdmin from './actions/report-api/moderate_admin.actions'
import importRequests from './actions/import-requests.actions'
import upload from './actions/upload.actions'
import groupsAction from './actions/report-api/groups.action'
// Firebase
import companiesActions from './actions/companies'
import followUpActions from './actions/follow_ups'
import issuesActions from './actions/issues'
import notesActions from './actions/notes'
import supportActions from './actions/support'
import userActions from './actions/users'
import disclosuresActions from './actions/disclosures'
import bannerNoticesActions from './actions/banner_notices'
import documentsActions from './actions/documents'
import trainingsActions from './actions/trainings'
import notificationsActions from './actions/notifications'

Vue.use(Vuex)

export const storeObject = {
  state,
  getters,
  mutations,

  actions: {
    ...upload,
    ...areasActions,
    ...companiesActions,
    ...employeesActions,
    ...followUpActions,
    ...issuesActions,
    ...notesActions,
    ...reportsActions,
    ...supportActions,
    ...userActions,
    ...misconductsActions,
    ...analyticsActions,
    ...disclosuresActions,
    ...moderateAdmin,
    ...importRequests,
    ...bannerNoticesActions,
    ...documentsActions,
    ...trainingsActions,
    ...notificationsActions,
    ...groupsAction,

    async checkPermission({ state, getters, commit, dispatch }, capabilities) {
      if (!state.permissions.length) {
        await dispatch('getAccount', state.employeeId)
      }

      const hasPermission = await getters.hasAnyPermission(capabilities)

      if (!hasPermission) {
        commit('setError', 'missing-permission')
        Logger.error('missing-permission', { capabilities })
        router.push('/settings')
        throw new Error('missing-permission')
      }
    },
  },
}

const store = new Vuex.Store(storeObject)
export default store
