import firebase from 'firebase/app'

import { Auth, DB } from '../../plugins/firebase'
import store from '../index'
import Logger from '../../plugins/logger'
import Metrics from '../../plugins/metrics'
import { ForbiddenException, GenericError } from '../../plugins/errors'
import { AuthAPI } from '../../services'
import {
  EMPLOYEE_ID,
  TENANT,
  TOKEN,
  AUTHENTICATION_LOCAL_STORAGE_KEYS as authLocalStorageKeys,
} from '../../globals'

const actions = {
  async signInWithCredentials({ dispatch, commit }, { email, password }) {
    const response = await Auth.signInWithEmailAndPassword(
      email.trim(),
      password,
    ).catch((error) => {
      if (error?.code === 'auth/multi-factor-auth-required') {
        Logger.debug(error.code, {
          tags: [['action', 'users/sign-in-with-credentials']],
          user: { email: Logger.maskEmail(email) },
        })
        throw new GenericError('TWO_FACTOR_LEGACY_ERROR')
      }

      dispatch('signOut')
      commit('setError', error.code)
      throw new ForbiddenException(error.code)
    })

    if (!response) {
      return false
    }

    const { user, resolver } = response

    if (resolver) {
      return { resolver }
    }

    const { claims } = await user.getIdTokenResult().catch((err) => {
      Logger.debug(err.message, {
        tags: [['action', 'users/sign-in-with-credentials']],
        user,
      })
    })

    if (claims.admin) {
      commit('setCurrentUser', user)
    } else {
      dispatch('signOut')
      commit('setError', 'access-denied')
    }

    return { user: { ...user, ...claims } }
  },

  async getCompanyByUserEmail({ dispatch }, { email }) {
    try {
      if (!email) {
        throw new Error('Invalid email')
      }

      const user = await AuthAPI.getUserByEmail(email)

      return dispatch('getCompanyById', user?.claims)
    } catch (error) {
      Logger.debug(error.message, {
        tags: [['action', 'auth/get-user-by-email']],
        user: { email: Logger.maskEmail(email) },
      })
      throw error
    }
  },

  async getUserToken({ commit, dispatch }) {
    const user = Auth.currentUser
    if (!user) {
      return
    }

    commit('setCurrentUser', user.uid)

    Logger.setUser({
      name: user.displayName,
      email: Logger.maskEmail(user.email),
    })

    const { claims, token } = await user.getIdTokenResult(true).catch((err) => {
      Logger.error(err.message, {
        tags: [['action', 'users/get-user-token']],
        user: {
          name: user.displayName,
          uid: user.uid,
          email: Logger.maskEmail(user.email),
        },
      })
      commit('setError', err.code)
    })

    const { employeeId: adminId, admin, tenant, schemaName, name } = claims

    Logger.setUserProperty({
      name: user.displayName,
      employeeId: adminId,
      schemaName: schemaName || tenant,
      email: Logger.maskEmail(user.email),
    })

    if (!adminId || !admin) {
      await dispatch('signOut')
      commit('setError', 'access-denied')
      return
    }

    commit('mutate', { property: 'token', value: token })
    commit('mutate', { property: 'tenant', value: schemaName || tenant })
    commit('mutate', { property: 'adminId', value: adminId })
    commit('mutate', { property: 'employeeName', value: name })

    Metrics.identify(user.uid)
    Metrics.people.set({
      // prettier-ignore
      '$name': name,
      // prettier-ignore
      '$email': user.email,
      'Employee ID': adminId,
      // prettier-ignore
      'Tenant': tenant,
    })

    localStorage.setItem(TOKEN, token)
    localStorage.setItem(TENANT, tenant)
    localStorage.setItem(EMPLOYEE_ID, adminId)

    await dispatch('getAccount', adminId)
    await dispatch('getCompanyByTenant')
  },

  async signInWithSAML({ commit, dispatch }, provider) {
    try {
      localStorage.removeItem(TOKEN)

      const samlProvider = new firebase.auth.SAMLAuthProvider(provider)

      const { additionalUserInfo, user } = await Auth.signInWithPopup(
        samlProvider,
      )

      if (user?.uid) {
        dispatch('upsertEmployee', { additionalUserInfo, user })
      }

      return user
    } catch (error) {
      Logger.warn(error?.code || error?.message || 'SSO Error', {
        tags: [['action', 'users/sign-in-with-saml']],
        provider,
      })
      commit('setError', error.message)
      throw error
    }
  },

  async upsertEmployee({ state }, result) {
    const { additionalUserInfo, user } = result

    if (!user?.uid) {
      return
    }

    const { token } = await Auth.currentUser.getIdTokenResult(true)

    if (token) {
      localStorage.setItem(TOKEN, token)
    }

    const { profile, providerId } = additionalUserInfo

    const userInfo = {
      uid: user.uid,
      name: [profile?.firstName, profile?.lastName].join(' '),
      ...profile,
      providerId,
      companyId: state.companyId,
    }

    return AuthAPI.upsertUser(userInfo)
  },

  async signOut({ state }) {
    await Auth.signOut()

    for (let key of authLocalStorageKeys) {
      localStorage.removeItem(key)
    }

    Logger.clearUser()

    store.replaceState(state)
  },

  /**
   * Send email with reset password instructions
   */
  async sendResetPasswordInstructions({ commit }, email) {
    await AuthAPI.resetPassword(email.trim())
      .then(() => {
        commit('mutate', {
          property: 'notice',
          value: 'Enviamos um email com instruções de acesso.',
        })
        commit('mutate', { property: 'error', value: null })
        commit('mutate', { property: 'loading', value: false })
      })
      .catch((error) => {
        commit('mutate', { property: 'error', value: error.message })
      })
      .finally(() => {
        commit('mutate', { property: 'notice', value: null })
        commit('mutate', { property: 'loading', value: false })
      })
  },

  updateCredentials({ commit }, payload) {
    const { email } = Auth.currentUser

    const credentials = firebase.auth.EmailAuthProvider.credential(
      email,
      payload.old_password,
    )

    Auth.currentUser
      .reauthenticateWithCredential(credentials)
      .then(() => {
        Auth.currentUser
          .updatePassword(payload.password)
          .then(() => {
            commit('setError', null)
            commit('mutate', {
              property: 'notice',
              value: 'Senha atualizada. Guarde-a em um lugar seguro!',
            })
          })
          .catch(() => commit('setError', 'Senha Inválida! Tente novamente.'))
      })
      .catch(() => commit('setError', 'Ops, senha atual não confere.'))
  },

  async updateProfile({ state, commit }, payload) {
    await DB.collection('employees').doc(state.employeeId).update(payload)

    Auth.currentUser
      .updateProfile({
        displayName: payload.name,
      })
      .then(() => commit('setError', null))
      .catch((err) => commit('setError', err.message))
  },

  async sendMultiFactorToken({ state }, email) {
    const { tenant } = state.company

    return AuthAPI.sendMultiFactorToken({ email, tenant })
  },

  async verifyMultiFactorToken(_, { code, email }) {
    return AuthAPI.verifyMultiFactorToken(code, email)
  },
}

export default actions
