import Cookies from 'js-cookie'

export const getDDSessionId = () => {
  const ddCookies = (Cookies.get('_dd_s') || 'id=').split('&')
  const sessionIdCookie = ddCookies.find((item) => item.startsWith('id='))
  return sessionIdCookie.split('=')[1]
}

export default {
  getDDSessionId,
}
