import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/analytics'
import 'firebase/app-check'
const Timestamp = firebase.firestore.Timestamp
const FieldValue = firebase.firestore.FieldValue

const {
  VUE_APP_FIREBASE_API_KEY,
  VUE_APP_FIREBASE_AUTH_DOMAIN,
  VUE_APP_FIREBASE_DB_URL,
  VUE_APP_FIREBASE_PROJECT_ID,
  VUE_APP_FIREBASE_APP_ID,
  VUE_APP_MEASUREMENT_ID,
  VUE_APP_FIREBASE_EMULATOR,
  VUE_APP_RECAPTCHA_CLIENT_KEY,
} = process.env

const config = {
  apiKey: VUE_APP_FIREBASE_API_KEY,
  authDomain: VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: VUE_APP_FIREBASE_DB_URL,
  projectId: VUE_APP_FIREBASE_PROJECT_ID,
  appId: VUE_APP_FIREBASE_APP_ID,
  measurementId: VUE_APP_MEASUREMENT_ID,
}

if (VUE_APP_FIREBASE_EMULATOR === 'true') {
  config.databaseURL = `http://localhost:9000?ns=${config.projectId}`
}

const FirebaseAppInstance = firebase.initializeApp(config)

const DB = FirebaseAppInstance.firestore()
const Auth = FirebaseAppInstance.auth()
const Functions = FirebaseAppInstance.functions()
const Analytics = firebase.analytics()
const AppCheck = firebase.appCheck()
AppCheck.activate(VUE_APP_RECAPTCHA_CLIENT_KEY)

if (VUE_APP_FIREBASE_EMULATOR === 'true') {
  Auth.useEmulator('http://localhost:9099/')
  DB.useEmulator('localhost', 8088)
  Functions.useEmulator('http://localhost:5001')
}

const trackEvent = (name, data) => Analytics.logEvent(name, data)

const docIdField = firebase.firestore.FieldPath.documentId()

// Set session persistence
Auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)

import store from '../store'

FirebaseAppInstance.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = Auth.onAuthStateChanged(async (user) => {
      unsubscribe()
      await store.dispatch('getUserToken')
      resolve(user)
    }, reject)
  })
}

export default FirebaseAppInstance

export { Auth, DB, docIdField, FieldValue, Functions, Timestamp, trackEvent }
