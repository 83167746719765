<template>
  <section class="maintenance">
    <h2 class="color-pink">
      {{ $t('MAINTENANCE_TITLE') }}
    </h2>
    <p>{{ $t('MAINTENANCE_SUBTITLE') }}</p>

    <p>
      {{ $t('MAINTENANCE_TEXT') }}
    </p>

    <s-button btn-style="primary medium" link-to="https://safe.space/conteudo">
      {{ $t('BLOG_CTA') }}
    </s-button>

    <img
      :src="maintenanceImg"
      :alt="$t('MAINTENANCE_TITLE')"
      class="maintenance-img"
    />
  </section>
</template>

<script>
import SButton from './ui/s-button'

import maintenanceImg from '../assets/images/maintenance.svg'

export default {
  name: 'Maintenance',

  components: {
    SButton,
  },

  data() {
    return {
      maintenanceImg,
    }
  },
}
</script>

<style>
.maintenance {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: var(--default-height);
  justify-content: center;
  margin: 0 auto;
  max-width: 420px;
  padding: 0 20px;
  text-align: center;
  height: 100vh;
}

.maintenance h2 {
  margin-top: 20rem;
}

.maintenance .maintenance-img {
  width: 100%;
}

.maintenance .s-button {
  width: 100%;
  max-width: 300px;
}
</style>
