const getters = {
  filterRelationsBy:
    ({ company }) =>
    (field) => {
      if (!company?.relations) {
        return []
      }

      const relationsOptions = Object.keys(company.relations).map(
        (relation) => {
          return {
            key: relation,
            ...company.relations[relation],
          }
        },
      )

      const orderBy = (fisrtIndex, secondIndex) =>
        fisrtIndex.order < secondIndex.order ? -1 : null

      return relationsOptions
        .filter((relation) => relation[field])
        .sort(orderBy)
    },

  adminAlreadyAgreedWithTerms: ({ report, adminId }) => {
    if (!report.agreements?.length) {
      return false
    }

    return report.agreements.some(({ employeeId }) => employeeId === adminId)
  },

  hasAnyPermission:
    ({ permissions }) =>
    (capabilities = []) => {
      return permissions.some((item) => capabilities.includes(item))
    },

  hasPermission:
    ({ permissions }) =>
    (capability) => {
      return permissions?.includes(capability)
    },

  isAnalyticsEnabled: ({ config }) => {
    const { analytics = false } = config?.features || {}
    return analytics
  },

  isLocalesEnabled: ({ config }) => {
    const { locales = false } = config?.features || {}
    return locales
  },

  isReportRedirectionsEnabled: ({ config }) => {
    const { redirections = false } = config?.features || {}
    return redirections
  },

  hasAssigneeUpdatePermission: ({ reportAssignees }) => {
    let hasUpdatePermission = false

    reportAssignees?.find(function (value) {
      if (value.permissions == 'CAN_UPDATE') {
        hasUpdatePermission = true
      }
    })

    return hasUpdatePermission
  },

  isContentReadingEnabled: ({ config }) => {
    const { contentReading = false } = config?.features || {}
    return contentReading
  },

  isAdminReportsEnabled: ({ config }) => {
    const { adminReports = false } = config?.features || {}
    return adminReports
  },

  isDisclosureEnabled: ({ config }) => {
    const { disclosure } = config?.features || false
    return disclosure
  },

  isReportGroupsEnabled: ({ config }) => {
    const { reportGroups } = config?.features || false
    return reportGroups
  },

  isBoardViewEnabled: ({ config }) => {
    const { boardView } = config?.features || false
    return boardView
  },

  isAttachMisconductsEnabled: ({ config }) => {
    const { attachMisconduct } = config?.features || false
    return attachMisconduct
  },

  isCriticalLevelEnabled: ({ config }) => {
    const { criticalityLevel } = config?.features || false
    return criticalityLevel
  },

  enabledRelations: (_, { filterRelationsBy }) => {
    return filterRelationsBy('enabled')
  },

  isIntroPageEnabled: ({ config }) => {
    const { introductionPage } = config?.features || false
    return introductionPage
  },

  isReportCollaborator:
    ({ company }) =>
    (relationship) => {
      if (!relationship) {
        return false
      }

      const relation = company.relations[relationship]

      if (!relation) {
        return false
      }

      return relation.isCollaborator
    },

  isCompanyBranchEnabled: ({ config }) => {
    const { companyBranch = false } = config?.features || {}
    return companyBranch
  },

  // Toggle de Apuração de Relato
  isReportVerificationDataEnabled: ({ config }) => {
    const { reportVerificationData = false } = config?.features || {}
    return reportVerificationData
  },

  enabledAreas: ({ areas }) => {
    return areas.filter(({ enabled }) => enabled === true)
  },

  isDocumentsManagementEnabled: ({ config }) => {
    const { documentsManagement } = config?.features || false
    return documentsManagement
  },

  isTrainingsManagementEnabled: ({ config }) => {
    const { trainingsManagement } = config?.features || false
    return trainingsManagement
  },

  isReportingChannelEnabled: ({ config }) => {
    const { reportingChannel } = config?.features || false
    return reportingChannel
  },

  isInvestigationBotEnabled: ({ config }) => {
    const { investigationBot } = config?.features || false
    return investigationBot
  },

  isIssuesEnabled: ({ config }) => {
    const { issues } = config?.features || false
    return issues
  },
}

export default getters
