import { GET_GROUPS } from '@/graphql/queries'
import { apolloClient as graphql } from '@/apolloProvider'

export default {
  getGroups: async () => {
    const {
      data: { groups },
    } = await graphql.query({
      query: GET_GROUPS,
    })

    return groups.map((item) => ({
      link: `/group/${item.id}`,
      ...item,
    }))
  },
}
