import CompanyApi from '../../services/company-api.service'

const actions = {
  getTrainings: async ({ commit }) => {
    const { data } = await CompanyApi.getTrainings()
    const result = data.map(({ employeesProgress, ...rest }) => {
      const completedCount = employeesProgress.filter(
        (item) => item.completed,
      ).length
      return { ...rest, readers: completedCount }
    })

    commit('mutate', { property: 'trainings', value: result })
  },
  getTrainingById: async (_, id) => {
    const { data } = await CompanyApi.getTrainingById(id)

    return data
  },

  activateTraining: async (_, params) => {
    const { id } = params

    return CompanyApi.updateTraining(id, { status: 'PUBLISHED' })
  },

  deactivateTraining: async (_, params) => {
    const { id } = params

    return CompanyApi.updateTraining(id, { status: 'ARCHIVED' })
  },
}

export default actions
