import { authAPI as api } from '../plugins/axios'
import Logger from '../plugins/logger'
import { UnauthorizedException } from '../plugins/errors'

const AuthAPI = {
  getUserByEmail: async (email) => {
    const response = await api
      .get('/auth/users', {
        params: { email: email.trim() },
        headers: defaultHeaders,
      })
      .catch((err) => {
        const data = err?.response?.data

        Logger.debug(err.message, {
          user: { email: Logger.maskEmail(email) },
        })

        if (data?.statusCode === 500) {
          throw new UnauthorizedException('BAD_REQUEST')
        }

        if (data?.statusCode === 404) {
          throw new UnauthorizedException('LOGIN.USER_NOT_FOUND')
        }

        throw new Error('LOGIN.SECURITY_ERROR')
      })

    const { claims, disabled } = response.data
    if (!claims.admin || disabled) {
      Logger.debug('Admin sign in attempt failed', {
        user: {
          email,
          isAdmin: claims?.admin,
          isDisabled: disabled,
        },
      })

      throw new UnauthorizedException('LOGIN.USER_NOT_FOUND')
    }

    return { ...response.data, ...claims }
  },

  upsertUser: async (payload) => {
    return api.patch('/auth/sign-on', payload)
  },

  resetPassword: async (email) => {
    return api
      .post(
        '/auth/reset-password',
        { email: email.trim() },
        { headers: defaultHeaders },
      )
      .catch((err) => {
        const data = err?.response?.data

        Logger.debug(err.message, {
          user: { email: Logger.maskEmail(email) },
        })

        if (data?.statusCode === 500) {
          throw new UnauthorizedException('BAD_REQUEST')
        }

        if (data?.statusCode === 404) {
          throw new UnauthorizedException('LOGIN.USER_NOT_FOUND')
        }

        throw new Error('LOGIN.SECURITY_ERROR')
      })
  },

  sendMultiFactorToken: async ({ email, tenant }) => {
    return api.post(
      '/mfa/code',
      { email: email.trim() },
      {
        headers: {
          'x-schema': tenant,
          ...defaultHeaders,
        },
      },
    )
  },

  verifyMultiFactorToken: async (code, email) => {
    return api.post(
      '/mfa/verify',
      { code, email: email.trim() },
      { headers: defaultHeaders },
    )
  },
}

const defaultHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
}

export default AuthAPI
