import { Auth, DB, Timestamp } from '@/plugins/firebase'
import Logger from '@/plugins/logger'

import { groupPermissions } from '../permissions'

const actions = {
  async createFollowUp({ commit, state, dispatch }, payload) {
    await dispatch('checkPermission', groupPermissions.REPORT)

    commit('setLoading', true)

    try {
      payload.identity = state.employeeName
      payload.companyRef = DB.collection('companies').doc(state.companyId)
      payload.sentAt = Timestamp.fromDate(new Date())
      payload.userUID = Auth.currentUser.uid
      payload.read = false
      payload.source = 'ADMIN'
      payload.deleted = false

      await DB.collection('follow_ups').add(payload)

      if (payload.type === 'FILE') {
        payload.src = await dispatch('getS3FileUrl', {
          params: { file: payload.message },
        })
      }

      return payload
    } catch (error) {
      Logger.error(error.message, {
        tags: [['action', 'follow-ups/create-follow-up-reply']],
        companyId: state.companyId,
        reportId: payload.reportId,
        userUID: Auth.currentUser.uid,
      })

      commit('setError', error?.code || error?.message || 'failed-precondition')
    } finally {
      commit('setLoading', false)
    }
  },

  async getFollowUps({ commit, state, dispatch }, reportId) {
    commit('mutate', { property: 'replies', value: [] })
    commit('setLoading', true)

    if (!state.companyId) {
      await dispatch('getCompanyByTenant')
    }

    try {
      const companyRef = DB.collection('companies').doc(state.companyId)

      const { docs } = await DB.collection('follow_ups')
        .where('companyRef', '==', companyRef)
        .where('reportId', '==', reportId)
        .orderBy('sentAt', 'asc')
        .get()

      const followups = []

      for (const doc of docs) {
        const data = doc.data()

        if (data.type === 'FILE') {
          const fileUrl = await dispatch('getS3FileUrl', {
            params: { key: data.message },
          })

          if (fileUrl) {
            data.src = fileUrl
            data.thumb = fileUrl
          } else {
            data.error = 'ERROR_ON_RETRIEVE_FILE'
          }
        }

        // Firebase do not support where igual and difference
        if (data.type !== 'ACTIVITY') {
          followups.push({ id: doc.id, ...data })
        }
      }

      commit('mutate', { property: 'replies', value: followups })

      await dispatch('markUserRepliesAsRead', followups)

      return followups
    } catch (error) {
      Logger.error(error.message, {
        tags: [['action', 'follow-ups/get-follow-ups']],
        companyId: state.companyId,
        reportId,
        userUID: Auth.currentUser.uid,
      })

      commit('setError', error?.code || error?.message || 'failed-precondition')
    } finally {
      commit('setLoading', false)
    }
  },

  async deleteFollowUp({ state, commit, dispatch }, follow_ups_id) {
    await dispatch('checkPermission', groupPermissions.REPORT)
    commit('setLoading', true)

    if (!state.companyId) {
      await dispatch('getCompanyByTenant')
    }

    try {
      const payload = {
        deleted: true,
      }

      await DB.collection('follow_ups').doc(follow_ups_id).update(payload)
    } catch (error) {
      Logger.error(error.message, {
        tags: [['action', 'follow-ups/delete-follow-ups']],
        companyId: state.companyId,
        followUpId: follow_ups_id,
        userUID: Auth.currentUser.uid,
      })
    }
  },

  async getActivities({ state, commit }, reportId) {
    const companyRef = DB.collection('companies').doc(state.companyId)
    commit('setLoading', true)

    try {
      const { docs } = await DB.collection('follow_ups')
        .where('companyRef', '==', companyRef)
        .where('reportId', '==', reportId)
        .where('type', '==', 'ACTIVITY')
        .orderBy('sentAt', 'desc')
        .limit(20)
        .get()

      const activities = docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })

      commit('mutate', { property: 'activities', value: activities })

      return activities
    } catch (error) {
      Logger.error(error.message, {
        tags: [['action', 'follow-ups/get-activities']],
        companyId: state.companyId,
        reportId,
        userUID: Auth.currentUser.uid,
      })

      commit('setError', error?.code || error?.message || 'failed-precondition')
    } finally {
      commit('setLoading', false)
    }
  },

  async activityLog({ dispatch }, log) {
    const payload = {
      ...log,
      type: 'ACTIVITY',
      source: 'SYSTEM',
      visible: log.visible || false,
    }

    await dispatch('createFollowUp', payload)
    await dispatch('getActivities', payload.reportId)
  },

  async markUserRepliesAsRead({ state }, activities) {
    const userReplyIds = activities
      .filter((reply) => !reply.read && reply.source === 'USER')
      .map((reply) => reply.id)

    const messages = userReplyIds.map(async (followUpId) => {
      return DB.collection('follow_ups').doc(followUpId).update({ read: true })
    })

    return Promise.all(messages).catch((error) => {
      Logger.error(error.message, {
        tags: [['action', 'follow-ups/mark-user-replies-as-read']],
        companyId: state.companyId,
        userUID: Auth.currentUser.uid,
      })
    })
  },

  async getUnreadMessages({ state: { companyId, reports } }) {
    try {
      const companyRef = DB.collection('companies').doc(companyId)

      const promises = reports.map(async (report) => {
        const { size } = await DB.collection('follow_ups')
          .where('companyRef', '==', companyRef)
          .where('reportId', '==', report.id)
          .where('source', '==', 'USER')
          .where('read', '==', false)
          .get()

        report.unread = size
      })

      await Promise.all(promises)
    } catch (error) {
      Logger.debug(error.message, {
        tags: [['action', 'follow-ups/get-unread-messages']],
        companyId,
      })
      throw error
    }
  },
}

export default actions
