import CompanyApi from '../../services/company-api.service'

const actions = {
  getDocumentById: async (_, id) => {
    const { data } = await CompanyApi.getDocumentById(id)

    return data
  },

  getDocuments: async ({ commit }) => {
    const data = await CompanyApi.getDocuments()

    commit('mutate', { property: 'documents', value: data.data })
  },

  /**
   * @deprecated Use UploadService.getSignedURLFromCompanyAPI instead
   */
  getDocumentsSignedUrl: async (_, params) => {
    const { companyId, fileName } = params

    const payload = {
      key: fileName,
      prefix: 'policies',
    }

    return CompanyApi.getSignedUrl(companyId, payload)
  },

  createDocument: async (_, params) => {
    const { title, description, filekey, active } = params

    const status = active ? 'PUBLISHED' : 'DRAFT'

    const document = {
      title,
      description,
      file: {
        key: filekey,
        type: 'PDF',
      },
      status,
    }

    return CompanyApi.newDocument(document)
  },

  updateDocument: async (_, params) => {
    const { title, description, active, id, status, fileName, groups } = params

    let document = {
      title,
      description,
      groups,
    }

    const isDraftOldStatus = status === 'DRAFT'

    if (isDraftOldStatus) {
      document = {
        ...document,
        file: {
          key: fileName,
          type: 'PDF',
        },
      }
    }

    const isActiveOldStatus = status === 'PUBLISHED'

    if (active && !isActiveOldStatus) {
      document = {
        ...document,
        status: 'PUBLISHED',
      }
    }

    if (!active && isActiveOldStatus) {
      document = {
        ...document,
        status: 'ARCHIVED',
      }
    }

    return CompanyApi.updateDocument(id, document)
  },

  activateDocument: async (_, params) => {
    const { id } = params

    return CompanyApi.updateDocument(id, { status: 'PUBLISHED' })
  },

  deactivateDocument: async (_, params) => {
    const { id } = params

    return CompanyApi.updateDocument(id, { status: 'ARCHIVED' })
  },

  deletedDocument: async (_, { id }) => {
    return CompanyApi.deleteDocument(id)
  },

  /**
   * @deprecated Use UploadService.getFileURLFromCompanyAPI instead
   */
  getFileUrl: async (_, params) => {
    const { companyId, key } = params.params

    const payload = {
      key: key,
      prefix: 'policies',
    }

    const response = await CompanyApi.getFileUrl(companyId, payload)

    return response.data.url
  },
}

export default actions
