import { companyApi as api } from '../plugins/axios'
import Logger from '../plugins/logger'

const CompanyApi = {
  getDocumentById: async (id) => {
    const response = await api.get(`/documents/${id}`).catch((error) => {
      Logger.error(error.message)
    })

    return response.data
  },

  getDocuments: async () => {
    const response = await api.get('/documents').catch((error) => {
      Logger.error(error.message)
    })

    return response.data
  },

  getTrainings: async () => {
    const response = await api.get('/training/admin').catch((error) => {
      Logger.error(error.message)
    })

    return response.data
  },

  getSignedUrl: async (companyId, payload) => {
    const response = await api
      .post(`/companies/${companyId}/upload`, payload)
      .catch((error) => {
        Logger.error(error.message)
      })

    return response.data
  },

  newDocument: async (document) => {
    const response = await api.post('/documents', document).catch((error) => {
      Logger.error(error.message)
    })

    return response.data
  },

  updateDocument: async (documentId, documentProps) => {
    const response = await api
      .put(`/documents/${documentId}`, documentProps)
      .catch((error) => {
        Logger.error(error.message)
      })

    return response.data
  },

  deleteDocument: async (documentId) => {
    try {
      const response = await api.delete(`/documents/${documentId}`)
      return response.data
    } catch (error) {
      const errorMessage = `Error deleting policy: ${error.message}`
      throw new Error(errorMessage)
    }
  },

  updateTraining: async (trainingId, trainingProps) => {
    try {
      const response = await api.put(`/training/${trainingId}`, trainingProps)
      return response.data
    } catch (error) {
      const errorMessage = `Failed training status change: ${error.message}`
      throw new Error(errorMessage)
    }
  },

  getFileUrl: async (companyId, payload) => {
    const { key, prefix } = payload
    const response = await api
      .get(`/companies/${companyId}/upload?key=${key}&prefix=${prefix}`)
      .catch((error) => {
        Logger.error(error.message)
      })

    return response?.data
  },

  listImportRequests: async ({ page = 1 }) => {
    const response = await api
      .get('/imports', { params: { page } })
      .catch((error) => {
        Logger.error(error.message)
      })

    return response?.data?.data
  },

  createImportRequest: async ({ file }) => {
    const payload = {
      file: {
        key: file.key,
        type: 'XLSX',
      },
    }

    const response = await api.post('/imports', payload).catch((error) => {
      Logger.error(error.message)
    })

    return response.data
  },

  getTrainingById: async (id) => {
    const response = await api.get(`/training/${id}/admin`).catch((error) => {
      Logger.error(error.message)
    })

    return response.data
  },

  async triggerNotifications(params) {
    try {
      const response = await api.post('/notifications', params)
      return response
    } catch (error) {
      const errorMessage = `Failed trigger notifications: ${error.message}`
      Logger.error(errorMessage)
      throw errorMessage
    }
  },
}

export default CompanyApi
