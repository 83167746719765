<template>
  <label class="checkbox">
    <input v-model="selected" type="checkbox" :value="inputValue" />
    <p>
      {{ label }}
    </p>
  </label>
</template>

<script>
export default {
  name: 'FilterOptions',

  props: {
    value: {
      required: true,
      type: Array,
    },
    inputValue: {
      required: true,
      type: [String, Number],
    },
    label: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      maxLength: 15,
    }
  },

  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style scoped>
.checkbox p {
  max-width: 90px;
  margin: 0;
}
</style>
