<template>
  <div class="charts-container">
    <iframe
      id="dashboard-iframe"
      :src="iframeValue"
      frameborder="0"
      allowtransparency
    />
  </div>
</template>

<script>
import resizer from 'iframe-resizer'
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: 'ChartsContainer',

  data: () => {
    return {
      iframeValue: '',
    }
  },

  computed: {
    ...mapState(['company', 'locale']),
    ...mapGetters(['isCompanyBranchEnabled']),

    currentDate() {
      return new Date()
    },

    localeFormated() {
      if (this.locale === 'pt-BR') {
        return 'pt'
      }

      return this.locale
    },

    monthMessage() {
      const month = new Date().toLocaleString(this.locale, {
        month: 'long',
      })
      const year = new Date().getFullYear()

      return `${this.$t('PDF_MONTH')} ${this.$t(month)}/${year}`
    },
  },

  watch: {
    async localeFormated() {
      this.iframeValue = await this.getDashboard()
    },
  },

  async mounted() {
    resizer.iframeResize(
      {
        minHeight: '100%',
        minWidth: '100%',
        autoResize: true,
        scrolling: true,
      },
      '#dashboard-iframe',
    )
    this.iframeValue = await this.getDashboard()
  },

  methods: {
    ...mapActions(['getAnalyticsReports', 'getMetabase']),

    async getDashboard() {
      return await this.getAnalyticsReports(this.localeFormated)
    },
  },
}
</script>

<style lang="css">
.charts-container {
  min-height: 100%;
  min-width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
}
</style>
