export class ForbiddenException extends Error {
  constructor(message) {
    super(message)
    this.name = 'ForbiddenException'
  }
}

export class UnauthorizedException extends Error {
  constructor(message) {
    super(message)
    this.name = 'UnauthorizedException'
  }
}

export class NotFoundException extends Error {
  constructor(message) {
    super(message)
    this.name = 'NotFoundException'
  }
}

export class AuthenticationRequestError extends Error {
  constructor(message, cause) {
    super(message)
    this.response = cause
    this.name = 'AuthenticationError'
  }
}

export class GenericError extends Error {
  constructor(message, cause) {
    super(message)
    this.response = cause
    this.name = 'GenericError'
  }
}
